import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { LeadPageOfferManagementDropdown } from '@components/OfferManagementDropdown';
import { PageHeader } from '@components/PageHeader/PageHeader';
import { useToggle } from '@hooks/useToggle';
import { mdiChevronLeft } from '@mdi/js';
import { Drawer, IconButton, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { LeadAssignButtons } from '@pages/NewLeads/LeadAssignButtons';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { useGetLead, useGetLeadProjects } from '@services/api/leads/lead-info';
import { EditOfferAgreementProps, useUpdateOfferAgreement } from '@services/api/offerAgreements/offerAgreements';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PriceDropdown } from './offer/price/PriceDropdown';
import { AssignEntitiesPanel } from './AssignEnitiesPanel/AssignEntitiesPanel';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { CommaSeparatedTitle } from '@components/CommaSeparatedTitle/CommaSeparatedTitle';
import { LinkTabs } from '@components/Tabs/LinkTabs';

export const LeadsInfo = () => {
  const pushDataLayer = useAnalytics();
  const {
    translations: {
      leads: {
        details: { tabs },
      },
    },
    translate,
  } = useTranslations();
  const theme = useTheme();
  const isMinWidth = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const params = useParams<{ leadId: string }>();
  const { lead, isLoadingLead } = useGetLead();
  const { leadProjects } = useGetLeadProjects();
  const isUserPartner = useSelector(selectIsPartnerUser);
  const { updateOfferAgreementAsync, isUpdatingOfferAgreement } = useUpdateOfferAgreement();
  const [isAssignPanelOpen, toggleIsAssignPanelOpen] = useToggle();
  const navigate = useNavigate();

  const headerTitle = lead?.address ? (
    <CommaSeparatedTitle firstPart={lead?.address.street} secondPart={lead?.address.city} />
  ) : (
    `...`
  );

  const handleUpdateLeadStatus = (values: EditOfferAgreementProps) => {
    pushDataLayer({
      event: AnalyticEvents.statusUpdate,
      projectType: leadProjects?.map((project) => project.type).join(`, `),
      offerStatus: values.status,
    });
    return updateOfferAgreementAsync(values);
  };

  return (
    <ColumnLayoutContent
      isOpen={Boolean(params.leadId)}
      header={
        <Stack direction="row" justifyContent="space-between">
          <PageHeader
            title={headerTitle}
            actions={<LeadAssignButtons onAssignButtonClick={toggleIsAssignPanelOpen} />}
            isLoading={isLoadingLead}
          />
          {isMinWidth && (
            <Stack direction="row" gap={1} flexWrap="wrap" justifyContent="flex-end">
              <PriceDropdown />
              <LeadPageOfferManagementDropdown
                onAccept={handleUpdateLeadStatus}
                isUpdating={isUpdatingOfferAgreement}
              />
            </Stack>
          )}
        </Stack>
      }
      mobileTopHeader={
        <Stack direction="row" justifyContent="space-between" sx={{ mt: -1 }}>
          <IconButton onClick={() => navigate(-1)} sx={{ ml: -2 }}>
            <MdiIconWrapper path={mdiChevronLeft} />
          </IconButton>
          <Stack direction="row">
            <PriceDropdown />
            <LeadPageOfferManagementDropdown onAccept={handleUpdateLeadStatus} isUpdating={isUpdatingOfferAgreement} />
          </Stack>
        </Stack>
      }
      mobileScrollHeader={
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" component="h1" maxWidth={200}>
            {isLoadingLead ? <Skeleton variant="text" /> : headerTitle}
          </Typography>
          <LeadPageOfferManagementDropdown onAccept={updateOfferAgreementAsync} isUpdating={isUpdatingOfferAgreement} />
        </Stack>
      }
    >
      <LinkTabs
        tabs={[
          { href: `/leads/${params.leadId}`, label: translate(tabs.customer) },
          { href: `/leads/${params.leadId}/project`, label: translate(tabs.project) },
          { href: `/leads/${params.leadId}/offer`, label: translate(tabs.offer) },
        ]}
      />
      {!isUserPartner && (
        <Drawer open={isAssignPanelOpen} onClose={toggleIsAssignPanelOpen} anchor="right">
          <AssignEntitiesPanel onClose={toggleIsAssignPanelOpen} />
        </Drawer>
      )}
    </ColumnLayoutContent>
  );
};
