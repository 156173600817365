import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@redux/reducers';
import { createSelector } from 'reselect';

export interface ProductManagementState {
  selectedManageableProductId?: number;
  selectedManageableProductPackageId?: number;
  selectedManageableProductCategoryId?: number;
}

const initialState: ProductManagementState = {};

const productManagementSlice = createSlice({
  name: `PRODUCT_MANAGEMENT`,
  initialState,
  reducers: {
    setSelectedManageableProductId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedManageableProductId = action.payload;
    },
    setSelectedManageableProductPackageId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedManageableProductPackageId = action.payload;
    },
    setSelectedManageableProductCategoryId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedManageableProductCategoryId = action.payload;
    },
  },
});

export const {
  setSelectedManageableProductId,
  setSelectedManageableProductPackageId,
  setSelectedManageableProductCategoryId,
} = productManagementSlice.actions;
export const { reducer: productManagementReducer } = productManagementSlice;

export const selectProductManagementState = (state: AppState) => state.ProductManagement;
export const selectSelectedManageableProductId = createSelector(
  selectProductManagementState,
  (productManagement) => productManagement.selectedManageableProductId,
);

export const selectSelectedManageableProductPackageId = createSelector(
  selectProductManagementState,
  (productManagement) => productManagement.selectedManageableProductPackageId,
);

export const selectSelectedManageableProductCategoryId = createSelector(
  selectProductManagementState,
  (productManagement) => productManagement.selectedManageableProductCategoryId,
);
