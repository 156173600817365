import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Stack, Typography } from '@mui/material';
import { ManageableProductCategoryRowDto } from '@generatedTypes/data-contracts';
import { InputForAttribute } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AttributeSection/InputForAttribute/InputForAttribute';

type AttributeSectionProps = {
  selectedCategory?: ManageableProductCategoryRowDto;
};
export const AttributeSection: React.FC<AttributeSectionProps> = ({ selectedCategory }) => {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { addEditForm },
      },
    },
  } = useTranslations();

  if (!selectedCategory) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h4">{translate(addEditForm.attributeHeader)}</Typography>
      {selectedCategory.attributes.map((attribute, index) => (
        <InputForAttribute key={attribute.id} name={`attributeValues.${index}`} attribute={attribute} />
      ))}
    </Stack>
  );
};
