import { RowType, Table } from '@components/Table/Table';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useGetManageableProducts } from '@services/api/productManagement/products';
import { useDispatch } from 'react-redux';
import {
  setSelectedManageableProductCategoryId,
  setSelectedManageableProductId,
  setSelectedManageableProductPackageId,
} from '@redux/reducers/slices/productManagement';
import { useGetManageableProductCategories } from '@services/api/productManagement/categories';

type Row = RowType & {
  name: string;
  eNumber: string | null | undefined;
  manufacturer: string;
  category: string;
};

type MaterialsTableProps = {
  openEditForm: () => void;
};

export const MaterialsTable: React.FC<MaterialsTableProps> = ({ openEditForm }) => {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { materialListTable },
      },
    },
  } = useTranslations();

  const dispatch = useDispatch();
  const { manageableProducts, isLoadingManageableProducts } = useGetManageableProducts();
  const { manageableProductCategories } = useGetManageableProductCategories();

  const onSelectMaterial = (materialId: number, categoryId: number) => {
    dispatch(setSelectedManageableProductId(materialId));
    dispatch(setSelectedManageableProductCategoryId(categoryId));
    openEditForm();
  };

  const onSelectMaterialPackage = (materialPackageId: number, categoryId: number) => {
    dispatch(setSelectedManageableProductPackageId(materialPackageId));
    dispatch(setSelectedManageableProductCategoryId(categoryId));
    openEditForm();
  };

  const rows: Row[] = manageableProducts.map(({ id, name, categoryName, manufacturerName, eNumber, isActive }) => ({
    id,
    name,
    eNumber,
    manufacturer: manufacturerName,
    category: categoryName,
    categoryId: manageableProductCategories.find(({ name }) => name === categoryName)?.id,
    disabled: !isActive,
  }));

  const columns: GridColDef<Row>[] = [
    {
      field: `name`,
      headerName: translate(materialListTable.columns.name),
      flex: 2,
      renderCell: (props) => (
        <Stack height="100%" justifyContent="center">
          <Typography>{props.value}</Typography>
          <Typography variant="subtitle1">{props.row.eNumber}</Typography>
        </Stack>
      ),
    },
    { field: `manufacturer`, headerName: translate(materialListTable.columns.manufacturer), flex: 1 },
    {
      field: `category`,
      headerName: translate(materialListTable.columns.category),
      flex: 1,
      align: `right`,
      headerAlign: `right`,
      renderCell: (props) =>
        props.row.disabled ? (
          <Stack height="100%" justifyContent="center">
            <Typography>{props.value}</Typography>
            <Typography variant="subtitle1">{translate(materialListTable.inactive)}</Typography>
          </Stack>
        ) : (
          <Stack height="100%" justifyContent="center">
            <Typography>{props.value}</Typography>
          </Stack>
        ),
    },
  ];

  return (
    <Table
      columns={columns}
      rows={rows}
      filterOn={[`name`, `manufacturer`, `category`, `eNumber`]}
      filterOnPlaceholder={translate(materialListTable.searchPlaceholder)}
      loading={isLoadingManageableProducts}
      onRowClick={({ row }) =>
        row.eNumber ? onSelectMaterial(row.id, row.categoryId) : onSelectMaterialPackage(row.id, row.categoryId)
      }
    />
  );
};
