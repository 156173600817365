import { useTranslations } from '@services/hooks/translations/useTranslations';
import '@components/slideInView/slideInView.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { COMMON_CONSTRAINTS, ParsedBackendValidationResults } from '@components/controls/validations';
import {
  CarChargingProjectDto,
  CreateCarChargingProjectRequest,
  LeadPropertyInfo,
  SubscriptionDescriptionDto,
  SubscriptionDto,
  UpdateCarChargingProjectRequest,
} from '@generatedTypes/data-contracts';
import { FormWrapper } from '@components/forms/form-wrapper';
import { useForm } from '@hooks/useForm/useForm';
import { ComplexFormFieldType, SimpleFormFieldType } from '@hooks/useForm/useFormTypes';
import { InputSuffixWrapper } from '@components/controls/InputSuffixWrapper';
import { Chips } from '@components/controls/chips';
import { getDescriptionRows } from '@pages/NewLeads/offer/subscription/utils';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';
import { useGetCarChargingProjectTemplates } from '@services/api/carChargingProjects/carChargingProjectsTemplates';
import { useGetCarChargingProjectSettings } from '@services/api/carChargingProjects/carChargingProjectsSettings';
import { getOptionsFromSettings } from '@pages/NewLeads/project/carChargingProject/optionsWithTranslations';
import { BoxList } from '@components/controls/box-list';
import { RichTextInputWrapper } from '@components/controls/rich-text-input-wrapper';
import { SelectWrapper } from '@components/controls/select-wrapper';
import { CheckBox } from '@components/controls/check-box';
import { ResultMaterialCount } from '@components/info/ResultMaterialCount';
import { SlideInViewFormWrapper } from '@components/slideInView/SlideInViewFormWrapper';
import { DeleteHeader } from '@components/DeleteHeader/DeleteHeader';
import { CarChargingResultProductCount } from '@pages/NewLeads/Projects/CarChargingForm';

const SubscriptionOptionLabel = ({
  name,
  description,
}: {
  name?: string;
  description?: SubscriptionDescriptionDto;
}) => (
  <>
    <p>{name}</p>
    {getDescriptionRows(description)}
  </>
);

export type FormInputs = Omit<CreateCarChargingProjectRequest | UpdateCarChargingProjectRequest, `id` | `leadId`>;
export type Grid12ColLayoutMode = `centerd-narow` | `full-width`;

type CarChargingProjectProps = {
  onSubmit: (inputs: FormInputs) => void;
  onDelete: (projectId: number) => void;
  onClose: () => void;
  projectDetails: CarChargingProjectDto | null;
  leadPropertyDetails: LeadPropertyInfo | null;
  beValidationResults?: ParsedBackendValidationResults | null;
  isDisabled?: boolean;
  subscriptions: SubscriptionDto[] | null;
  layout?: Grid12ColLayoutMode;
  productResultCounter: CarChargingResultProductCount | null;
  setCarChargingPowerCount: (carChargerId: number) => number | undefined;
  setCarChargingRouterPortsCounter: (carChargingRouterTypeId: number) => number | undefined;
};

export const CarChargingProject: React.FC<CarChargingProjectProps> = ({
  onClose,
  onSubmit,
  onDelete,
  projectDetails,
  beValidationResults,
  isDisabled,
  subscriptions,
  productResultCounter: productCount,
  setCarChargingPowerCount,
  setCarChargingRouterPortsCounter,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: { header, carCharging },
          },
          propertyDetails,
        },
      },
    },
  } = useTranslations();

  const carChargingProjectSettings = useGetCarChargingProjectSettings();
  const carChargingProjectTemplates = useGetCarChargingProjectTemplates();

  const [gsmSelected, setGsmSelected] = useState(false);

  const settingsOptions = useMemo(() => {
    const settingsLabelsValuesOptions = getOptionsFromSettings(carChargingProjectSettings);
    const CarChargerSocketPower = settingsLabelsValuesOptions?.CarChargerSocketPower?.map((socketPower) => ({
      ...socketPower,
      disabled: !!productCount?.carChargingPowerProductCount?.find(
        (socketPowerCounters) =>
          socketPowerCounters.id === Number(socketPower.value) && socketPowerCounters.counter === 0,
      ),
    }));
    const RouterSwitchPorts = settingsLabelsValuesOptions?.RouterSwitchPorts?.map((switchPorts) => ({
      ...switchPorts,
      disabled: !!productCount?.carChargingRouterAndSwitchProductCount?.find(
        (socketPowerCounters) =>
          socketPowerCounters.id === Number(switchPorts.value) && socketPowerCounters.counter === 0,
      ),
    }));
    return {
      ...settingsLabelsValuesOptions,
      CarChargerSocketPower,
      RouterSwitchPorts,
    };
  }, [
    carChargingProjectSettings,
    productCount?.carChargingPowerProductCount,
    productCount?.carChargingRouterAndSwitchProductCount,
  ]);

  const subscriptionOptionsData = useMemo(
    () =>
      (subscriptions ?? []).map((subscription) => ({
        value: subscription.id,
        label: SubscriptionOptionLabel({ name: subscription?.name, description: subscription?.description }),
      })),
    [subscriptions],
  );

  const templatesOptionsData = useMemo(
    () =>
      (carChargingProjectTemplates ?? []).map((template) => ({
        value: String(template.value),
        label: template.label ?? ``,
      })),
    [carChargingProjectTemplates],
  );

  const { formFields, validate, valuesForSubmit, setAllValues, resetAllValues, refreshSchema } = useForm(
    {
      numberOfSockets: {
        type: SimpleFormFieldType.numeric,
        constraints: [COMMON_CONSTRAINTS.required],
        defaultValue: 1,
        isRequired: true,
      },
      carChargerSocketPowerId: {
        type: ComplexFormFieldType.radio,
        options: settingsOptions?.CarChargerSocketPower,
        defaultValue: settingsOptions?.CarChargerSocketPower?.[0]?.value,
      },
      carChargerSocketsPerChargerId: {
        type: ComplexFormFieldType.radio,
        options: settingsOptions?.CarChargerSocketsPerCharger,
        defaultValue: settingsOptions?.CarChargerSocketsPerCharger?.[0]?.value,
      },
      carChargerPlacementId: {
        type: ComplexFormFieldType.radio,
        options: settingsOptions?.CarChargerPlacement,
        defaultValue: settingsOptions?.CarChargerPlacement?.[0]?.value,
      },
      carChargerMountingId: {
        type: ComplexFormFieldType.radio,
        options: settingsOptions?.CarChargerMounting,
        defaultValue: settingsOptions?.CarChargerMounting?.[0]?.value,
      },
      distanceFromCentralToFirstCharger: {
        type: SimpleFormFieldType.numericString,
        defaultValue: `0,0`,
        precision: 2,
        isRequired: true,
      },
      distanceBetweenChargers: {
        type: SimpleFormFieldType.numericString,
        defaultValue: `0,0`,
        precision: 2,
        isRequired: true,
      },
      routerSwitchTypeId: {
        type: ComplexFormFieldType.radio,
        options: settingsOptions?.RouterSwitchType,
        defaultValue: settingsOptions?.RouterSwitchType?.[0]?.value,
      },
      routerSwitchPortsId: {
        type: ComplexFormFieldType.radio,
        options: settingsOptions?.RouterSwitchPorts,
        defaultValue: settingsOptions?.RouterSwitchPorts?.[0]?.value,
      },
      projectProductTemplateId: {
        type: ComplexFormFieldType.dropdown,
        options: templatesOptionsData,
        constraints: [COMMON_CONSTRAINTS.required],
        isRequired: true,
      },
      subscriptionId: {
        type: ComplexFormFieldType.radio,
        options: subscriptionOptionsData,
        defaultValue: subscriptionOptionsData?.[0]?.value,
        isRequired: true,
      },
      comment: {
        type: SimpleFormFieldType.text,
      },
    },
    { beValidationResults, isDisabled },
  );

  useEffect(() => {
    refreshSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productCount?.carChargingPowerProductCount,
    productCount?.carChargingRouterAndSwitchProductCount,
    subscriptionOptionsData,
    templatesOptionsData,
    isDisabled,
  ]);

  useEffect(() => {
    if (projectDetails) {
      setAllValues({
        numberOfSockets: projectDetails.numberOfSockets,
        carChargerPlacementId: projectDetails?.carChargerPlacementId ?? undefined,
        carChargerMountingId: projectDetails?.carChargerMountingId ?? undefined,
        distanceFromCentralToFirstCharger: projectDetails.distanceFromCentralToFirstCharger,
        distanceBetweenChargers: projectDetails.distanceBetweenChargers,
        routerSwitchTypeId: projectDetails?.routerSwitchTypeId ?? undefined,
        carChargerSocketPowerId: projectDetails?.carChargerSocketPowerId ?? undefined,
        carChargerSocketsPerChargerId: projectDetails?.carChargerSocketsPerChargerId ?? undefined,
        routerSwitchPortsId: projectDetails?.routerSwitchPortsId ?? undefined,
        projectProductTemplateId: templatesOptionsData.find(
          (template) => template.value === String(projectDetails.projectProductTemplateId),
        ),
        subscriptionId: projectDetails.subscriptionId,
        comment: projectDetails?.comment ?? ``,
      });
      if (projectDetails?.routerSwitchTypeId) {
        setGsmSelected(true);
      }
    } else {
      resetAllValues();
      setGsmSelected(false);
    }
    // TODO: check full list of useEffect dependencies to add or remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails, templatesOptionsData]);

  useEffect(() => {
    if (!gsmSelected) {
      formFields.routerSwitchTypeId.setValue(settingsOptions?.RouterSwitchType?.[0]?.value);
      formFields.routerSwitchPortsId.setValue(settingsOptions?.RouterSwitchPorts?.[0]?.value);
    }
  }, [
    formFields.routerSwitchTypeId,
    formFields.routerSwitchPortsId,
    gsmSelected,
    settingsOptions?.RouterSwitchType,
    settingsOptions?.RouterSwitchPorts,
  ]);

  const handleSubmit = useCallback(() => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    onSubmit({
      ...valuesForSubmit,
      subscriptionId: Number(valuesForSubmit.subscriptionId),
      projectProductTemplateId: Number(valuesForSubmit.projectProductTemplateId),
    } as FormInputs);
    resetAllValues();
  }, [onSubmit, validate, valuesForSubmit, resetAllValues]);

  const handleDelete = useMemo(() => {
    if (projectDetails?.id && !isDisabled) {
      return () => onDelete(projectDetails?.id ?? -1);
    }
    return null;
  }, [isDisabled, onDelete, projectDetails?.id]);

  const handleChangeSocketsPerCharger = (carChargerSocketsPerChargerId: string) => {
    if (!valuesForSubmit.carChargerSocketPowerId) {
      return;
    }
    const firstAvailableProductId = setCarChargingPowerCount(Number(carChargerSocketsPerChargerId));
    formFields.carChargerSocketPowerId.setValue(firstAvailableProductId);
  };

  const handleChangeRouterAndSwitchType = (routerSwitchTypeId: string) => {
    if (!valuesForSubmit.routerSwitchPortsId) {
      return;
    }
    const firstAvailableProductId = setCarChargingRouterPortsCounter(Number(routerSwitchTypeId));
    formFields.routerSwitchPortsId.setValue(firstAvailableProductId);
  };

  const handleGsmSelected = useCallback(() => {
    setGsmSelected((gsmSelected) => {
      if (gsmSelected) {
        formFields.routerSwitchTypeId.setValue(undefined);
        formFields.routerSwitchPortsId.setValue(undefined);
      }
      return !gsmSelected;
    });
  }, [formFields.routerSwitchTypeId, formFields.routerSwitchPortsId]);

  const chargerPortResultCount = productCount?.carChargingPowerProductCount?.find(
    (charger) => charger.id === Number(valuesForSubmit.carChargerSocketPowerId),
  )?.counter;

  const routerSwitchResultCount = productCount?.carChargingRouterAndSwitchProductCount?.find(
    (routerSwitchProduct) => routerSwitchProduct.id === Number(valuesForSubmit.routerSwitchPortsId),
  )?.counter;

  return (
    <SlideInViewFormWrapper
      header={translate(header)}
      onClose={onClose}
      onSubmit={handleSubmit}
      isDisabled={isDisabled}
    >
      <GridLayoutWithMargins>
        <DeleteHeader title={translate(propertyDetails.electricCarFacility)} onDelete={handleDelete} />
      </GridLayoutWithMargins>

      {/* This code might be needed in the future */}
      {/* more info here: https://dev.azure.com/RexelSE-Application/MyRexolution/_workitems/edit/4885 */}
      {/* <GridLayoutWithMargins>
        <h2 className={`bold`}>{translate(propertyDetails.title)}</h2>
        <PropertyDetailsContent leadPropertyDetails={leadPropertyDetails} />
      </GridLayoutWithMargins> */}

      {/* <GridLayoutWithMargins>
        <hr className="horizontal-line dark fw" />
      </GridLayoutWithMargins> */}

      <FormWrapper>
        <GridLayoutWithMargins>
          <div className="column gap-36">
            <h2 className="bold">{translate(carCharging.facility)}</h2>
            <section className="column gap-small">
              <h3 className="bold">{translate(carCharging.chargingPointsHeader)}</h3>

              <Chips
                label={translate(carCharging.chargingSocketsPerCharging)}
                inputFields={{
                  fields: formFields.carChargerSocketsPerChargerId.inputFields.fields,
                  value: formFields.carChargerSocketsPerChargerId.inputFields.value || `1`,
                }}
                onClickCallback={handleChangeSocketsPerCharger}
              />
            </section>

            <section className="column gap-small">
              <h3 className="bold">{translate(carCharging.chargerPlacementHeader)}</h3>
              <Chips
                label={translate(carCharging.locationCharger)}
                inputFields={{
                  fields: formFields.carChargerPlacementId.inputFields.fields,
                  value: formFields.carChargerPlacementId.inputFields.value || `1`,
                }}
              />

              <Chips
                label={translate(carCharging.chargerMounting)}
                inputFields={{
                  fields: formFields.carChargerMountingId.inputFields.fields,
                  value: formFields.carChargerMountingId.inputFields.value || `1`,
                }}
              />
            </section>

            <section className="column gap-small">
              <h3 className="bold">{translate(carCharging.distanceHeaderLong)}</h3>
              <div className={`grid-container`}>
                <div className="col-6">
                  <InputSuffixWrapper
                    label={translate(carCharging.distanceFromCentral)}
                    suffix="m"
                    {...formFields.distanceFromCentralToFirstCharger.inputFields}
                  />
                </div>
                <div className="col-6">
                  <InputSuffixWrapper
                    label={translate(carCharging.distanceBetweenChargers)}
                    suffix="m"
                    {...formFields.distanceBetweenChargers.inputFields}
                  />
                </div>
              </div>
            </section>
            <section className="column gap-small">
              <h3 className="bold">{translate(carCharging.connectionHeader)}</h3>

              <CheckBox
                label={translate(carCharging.gsmSelected)}
                checked={gsmSelected}
                onChange={handleGsmSelected}
                name={`gsmSelected`}
                value={``}
                isLabelOnRight
              />
            </section>
          </div>
        </GridLayoutWithMargins>
        <GridLayoutWithMargins>
          <hr className="horizontal-line dark fw" />
        </GridLayoutWithMargins>

        <GridLayoutWithMargins>
          <div className="column gap">
            <h2 className="bold">{translate(carCharging.templateHeader)}</h2>
            <p className="small">{translate(carCharging.templateDescription)}</p>
            <SelectWrapper
              label={translate(carCharging.productTemplate)}
              placeholder={translate(carCharging.productTemplatePlaceholder)}
              {...formFields.projectProductTemplateId.inputFields}
            />

            <div>
              <h3 className="bold">{translate(carCharging.electricCarCharger)}</h3>
              <ResultMaterialCount count={chargerPortResultCount} />
              <Chips
                label={translate(carCharging.powerChargingSocket)}
                inputFields={{
                  fields: formFields.carChargerSocketPowerId.inputFields.fields,
                  value: formFields.carChargerSocketPowerId.inputFields.value || `1`,
                }}
              />
            </div>
          </div>
        </GridLayoutWithMargins>

        {gsmSelected ? (
          <GridLayoutWithMargins>
            <div>
              <h3 className="bold">{translate(carCharging.routerSwitchIdHeader)}</h3>
              <ResultMaterialCount count={routerSwitchResultCount} />
              <div className="column gap-small">
                <Chips
                  label={translate(carCharging.routerSwitchLabel)}
                  {...formFields.routerSwitchTypeId}
                  onClickCallback={handleChangeRouterAndSwitchType}
                />
              </div>
            </div>
            <hr className="horizontal-line dark fw" />
            <div>
              <div className="column gap-small">
                <Chips label={translate(carCharging.routerNumberOfPorts)} {...formFields.routerSwitchPortsId} />
              </div>
            </div>
          </GridLayoutWithMargins>
        ) : null}

        <GridLayoutWithMargins>
          <hr className="horizontal-line dark fw" />
        </GridLayoutWithMargins>

        <GridLayoutWithMargins>
          <div className="column gap">
            <h2 className="bold">{translate(carCharging.subscriptionsHeader)}</h2>
            <BoxList inputFields={formFields.subscriptionId.inputFields} />
          </div>
        </GridLayoutWithMargins>

        <GridLayoutWithMargins>
          <hr className="horizontal-line dark fw" />
        </GridLayoutWithMargins>

        <GridLayoutWithMargins>
          <div className="column gap">
            <h2 className="bold">{translate(carCharging.notesHeader)}</h2>
            <RichTextInputWrapper
              label={translate(carCharging.comments)}
              placeholder={`${translate(carCharging.ownNotes)}...`}
              {...formFields.comment.inputFields}
            />
          </div>
        </GridLayoutWithMargins>
      </FormWrapper>
    </SlideInViewFormWrapper>
  );
};
