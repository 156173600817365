import { Controller, Path, useFormContext } from 'react-hook-form';
import { AddEditManageableMaterialOrPackageValues } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageForm';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

type ListInputProps = {
  label: string;
  name: Path<AddEditManageableMaterialOrPackageValues>;
  options: { attributeId: number; value: string; name: string }[];
};
export const ListInput: React.FC<ListInputProps> = ({ name, options, label }) => {
  const { control } = useFormContext<AddEditManageableMaterialOrPackageValues>();
  const [inputValue, setInputValue] = useState(``);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <Autocomplete
          onBlur={onBlur}
          onChange={(_, v) => onChange(v)}
          value={(value as AddEditManageableMaterialOrPackageValues[`attributeValues`][number]) ?? null}
          inputValue={inputValue}
          disableClearable
          selectOnFocus
          onInputChange={(_, v) => setInputValue(v)}
          autoSelect
          id={name}
          options={options || []}
          isOptionEqualToValue={(option, value) => String(option?.name) === String(value?.name)}
          getOptionLabel={(option) =>
            (option as AddEditManageableMaterialOrPackageValues[`attributeValues`][number])?.name ?? ``
          }
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              <span>{option.name}</span>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={label}
              ref={ref}
              error={!!error}
              helperText={error?.message}
              InputProps={{ ...params.InputProps, endAdornment: null }}
            />
          )}
        />
      )}
    />
  );
};
