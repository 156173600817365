import { DataGrid, DataGridProps, GridRowClassNameParams } from '@mui/x-data-grid';

import { svSE, enUS } from '@mui/x-data-grid/locales';
import { chosenLanguage } from '@redux/reducers/global';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions } from '../../styles/theme/theme';

const languageObject = chosenLanguage === `en` ? enUS : svSE;
const theme = createTheme(themeOptions, languageObject);
export const DataGridStyled = <Row extends { disabled?: boolean }>(props: DataGridProps) => (
  <ThemeProvider theme={theme}>
    <DataGrid<Row>
      {...props}
      getRowClassName={(params: GridRowClassNameParams<Row>): string =>
        params.row.disabled ? `MuiDataGrid-row-disabled` : ``
      }
      sx={(theme) => ({
        border: `none`,
        '--DataGrid-rowBorderColor': `transparent !important`,
        '--DataGrid-containerBackground': `white !important`,
        '& .MuiDataGrid-row-disabled': {
          color: theme.typography.caption.color,
          '& .MuiTypography-root': {
            color: theme.typography.caption.color,
          },
        },
        '& .MuiDataGrid-cell': {
          fontFamily: theme.typography.fontFamily,
          cursor: `pointer`,
        },
        '& .MuiTablePagination-selectLabel': {
          fontFamily: theme.typography.fontFamily,
        },
        '& .MuiDataGrid-cell:focus': {
          outline: `none`,
          outlineOffset: 0,
        },
        '& .MuiButtonBase-root': {
          color: theme.palette.text.primary,
        },
        '& .MuiTablePagination-displayedRows': {
          ...theme.typography.subtitle2,
        },
      })}
    />
  </ThemeProvider>
);
