import { ProductAttributeFilterType, ProductAttributeRowDto, ProductDto } from '@generatedTypes/data-contracts';
import { testNumberAttributeWithFilters } from './numberType/utils';
import { AppliedFilters } from './types';
import { testListAttributeWithFilters } from './listType/utils';
import flow from 'lodash.flow';

const testAttributesWithFilters = (filters: AppliedFilters) => (attributes: ProductAttributeRowDto[]) =>
  attributes.reduce(testAttributeWithFilters(filters), true);

const testAttributeWithFilters =
  (filters: AppliedFilters) => (defaultValue: boolean, attribute: ProductAttributeRowDto) => {
    if (!defaultValue) {
      return false;
    }
    const filterForAttribute = filters.filter(({ attributeId, active }) => attributeId === attribute.id && active);
    if (!filterForAttribute.length) {
      return true;
    }
    if (attribute.filterType === ProductAttributeFilterType.List) {
      return testListAttributeWithFilters({ attribute, filters: filterForAttribute });
    }
    if (attribute.filterType === ProductAttributeFilterType.Number) {
      return testNumberAttributeWithFilters({ attribute, filters: filterForAttribute });
    }
    return defaultValue;
  };

// This function merges the values for the same attribute from multiple rows into a single row
// This is needed because the backend can return multiple rows for the same attribute, with different values
const getMergedAttributeValuesForTheSameAttributeFromMultipleRows = (product: ProductDto) =>
  Array.from(
    product?.productAttributes
      ?.reduce<Map<number, ProductAttributeRowDto>>((reducedAttributes, attribute) => {
        const currentAttribute = { ...(reducedAttributes.get(attribute.id) ?? attribute) };
        currentAttribute.values = [...(currentAttribute.values ?? []), ...(attribute.values ?? [])];
        reducedAttributes.set(attribute.id, currentAttribute);
        return reducedAttributes;
      }, new Map())
      .values() ?? [],
  );

// This function tests if a product meets the criteria of the applied filters.
// Filters are applied top to bottom, so the first filter will be applied to all products, the second filter will be applied to the products that passed the first filter, and so on
const productMeetsFiltersCriteria = (filters: AppliedFilters) => (product: ProductDto) =>
  flow(getMergedAttributeValuesForTheSameAttributeFromMultipleRows, testAttributesWithFilters(filters))(product);
export const filterProducts = (allProducts: ProductDto[] | undefined, filters: AppliedFilters) =>
  allProducts?.filter(productMeetsFiltersCriteria(filters)) ?? [];
