import { ColumnItem } from '@components/columnLayout/utils';
import { useQueryClient } from 'react-query';
import { useUpdatePartnerCustomerInformation } from '@services/api/partners';
import { PARTNER, PARTNERS } from '@variables/queryKeys';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { PartnerDto } from '@generatedTypes/data-contracts';

import { z } from 'zod';
import {
  clientInformationZodObject,
  EditClientInformationForm,
  getDealerDropdownValues,
} from '@pages/partners/partnerDataForms/EditClientInformationForm';
import { useGetDealers } from '@services/api/dealers';

export const getClientInformationInitialValues = (partner: PartnerDto | null, defaultDealerValue: number) => ({
  dealerId: partner?.dealer?.id ?? defaultDealerValue,
  accountId: partner?.accountId ?? ``,
});

const clientInformationDataZodSchema = z.object(clientInformationZodObject);

export type ClientInformationValues = z.infer<typeof clientInformationDataZodSchema>;

type EditClientInformationProps = {
  partner: PartnerDto;
  onClose: () => void;
};

export const EditClientInformation: ColumnItem<EditClientInformationProps> = ({ partner, onClose }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdatePartnerCustomerInformation({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNERS);
      queryClient.invalidateQueries(PARTNER);
      onClose();
    },
  });
  const { dealers } = useGetDealers();

  const dealersDropdownValues = useMemo(() => getDealerDropdownValues(dealers), [dealers]);

  const defaultDealerValue = dealersDropdownValues?.[0]?.value;

  const initialValues = useMemo(
    () => getClientInformationInitialValues(partner, defaultDealerValue),
    [defaultDealerValue, partner],
  );

  const formFunctions = useForm<ClientInformationValues>({
    defaultValues: initialValues,
    resolver: zodResolver(clientInformationDataZodSchema),
  });
  const { handleSubmit, reset } = formFunctions;

  useEffect(() => {
    if (partner) {
      const selectedDealerOption = dealersDropdownValues.find((dealer) => dealer.value === partner.dealer?.id);

      reset({
        accountId: String(partner?.accountId ?? ``),
        dealerId: selectedDealerOption?.value ?? dealersDropdownValues[0]?.value,
      });
    }
  }, [dealersDropdownValues, partner, reset]);

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = useCallback(
    (values: ClientInformationValues) => {
      mutate({
        partnerId: partner?.id ?? 0,
        accountId: values.accountId,
        dealerId: values.dealerId ?? 0,
      });
    },
    [mutate, partner?.id],
  );

  return (
    <FormProvider {...formFunctions}>
      <FormWrapper onCancel={onCancel} onSubmit={handleSubmit(onSubmit)}>
        <EditClientInformationForm disableForm={isLoading} dealersDropdownValues={dealersDropdownValues} />
      </FormWrapper>
    </FormProvider>
  );
};
