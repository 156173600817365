import { PageHeader } from '@components/PageHeader/PageHeader';
import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Stack } from '@mui/material';
import { MaterialsTable } from '@pages/systemSettings/materialManagement/MaterialsTable';
import { Card } from '@components/Card/Card';
import { mdiPlus } from '@mdi/js';
import { useToggle } from '@hooks/useToggle';
import Drawer from '@mui/material/Drawer';
import { ManageableProductCategoryList } from '@pages/systemSettings/materialManagement/ManageableProductCategoryList';
import { ManageableProductCategoryRowDto } from '@generatedTypes/data-contracts';
import { useDispatch } from 'react-redux';
import {
  setSelectedManageableProductCategoryId,
  setSelectedManageableProductId,
  setSelectedManageableProductPackageId,
} from '@redux/reducers/slices/productManagement';
import { AddEditManageableMaterialOrPackageFormWrapper } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageFormWrapper';

export const MaterialManagement = () => {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { header, materialListTable },
      },
    },
  } = useTranslations();

  const dispatch = useDispatch();

  const [isManageableProductCategoryListOpen, toggleIsManageableProductCategoryListOpen] = useToggle();
  const [isManageableProductFormOpen, toggleIsManageableProductFormOpen] = useToggle();
  const onSelectCategory = (categoryId: ManageableProductCategoryRowDto[`id`]) => {
    dispatch(setSelectedManageableProductCategoryId(categoryId));
    toggleIsManageableProductCategoryListOpen();
    toggleIsManageableProductFormOpen();
  };

  const onFormClose = () => {
    toggleIsManageableProductFormOpen();
    dispatch(setSelectedManageableProductCategoryId(undefined));
    dispatch(setSelectedManageableProductId(undefined));
    dispatch(setSelectedManageableProductPackageId(undefined));
  };

  return (
    <>
      <ColumnLayoutContent isOpen header={<PageHeader title={translate(header)} />}>
        <Stack gap={2} className="cards__wrapper">
          <Card
            title={translate(materialListTable.header)}
            titleActionIcon={mdiPlus}
            titleAction={toggleIsManageableProductCategoryListOpen}
          >
            <MaterialsTable openEditForm={toggleIsManageableProductFormOpen} />
          </Card>
        </Stack>
      </ColumnLayoutContent>
      <Drawer
        open={isManageableProductCategoryListOpen}
        onClose={toggleIsManageableProductCategoryListOpen}
        anchor="right"
      >
        <ManageableProductCategoryList
          onClose={toggleIsManageableProductCategoryListOpen}
          onSelectCategory={onSelectCategory}
        />
      </Drawer>
      <Drawer open={isManageableProductFormOpen} onClose={onFormClose} anchor="right">
        <AddEditManageableMaterialOrPackageFormWrapper onClose={onFormClose} />
      </Drawer>
    </>
  );
};
