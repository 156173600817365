import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ProductAttributeFilterType } from '@generatedTypes/data-contracts';
import { Chip } from '@components/controls/chips';
import { SvgIcon } from '@components/controls/svgIcon';
import { Button } from '@mui/material';
import { ProductsAttributeFiltersSchemaType, AppliedFilters } from './utils/types';

const getChipLabel = (filter: ProductsAttributeFiltersSchemaType[number]): string => {
  switch (filter.type) {
    case ProductAttributeFilterType.List:
      return filter.valueName ?? ``;
    case ProductAttributeFilterType.Number:
      return `${filter.value} <`;
    default:
      return String((filter as ProductsAttributeFiltersSchemaType[number]).value);
  }
};

type FiltersChipsProps = {
  filters: AppliedFilters;
  onOpenProductAttributeFilters?: () => void;
};

const FiltersChips: React.FC<FiltersChipsProps> = ({ filters, onOpenProductAttributeFilters }) => (
  <div className="chips-container row gap-extra-small">
    {filters.map((filter) => {
      const chipLabel = getChipLabel(filter);
      return (
        <Chip
          name={chipLabel}
          value={filter.value}
          type="radio"
          key={filter.attributeId}
          version="small"
          label={chipLabel}
          onChange={onOpenProductAttributeFilters}
          onClick={onOpenProductAttributeFilters}
          checked={!!onOpenProductAttributeFilters}
          disabled={!onOpenProductAttributeFilters}
        />
      );
    })}
  </div>
);

type FiltersButtonsProps = {
  filters: AppliedFilters;
  onOpenProductAttributeFilters?: () => void;
};

export const FiltersButtons: React.FC<FiltersButtonsProps> = ({ filters, onOpenProductAttributeFilters }) => {
  const {
    translate,
    translations: {
      editProduct: {
        filtering: { filterButton },
      },
    },
  } = useTranslations();

  if (onOpenProductAttributeFilters) {
    if (filters.length > 0) {
      return <FiltersChips filters={filters} onOpenProductAttributeFilters={onOpenProductAttributeFilters} />;
    } else {
      return (
        <Button
          variant="text"
          sx={{ alignSelf: `flex-start` }}
          onClick={onOpenProductAttributeFilters}
          startIcon={<SvgIcon iconId="filter" />}
        >
          {translate(filterButton)}
        </Button>
      );
    }
  } else {
    return <FiltersChips filters={filters} />;
  }
};
