import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import { useCallback } from 'react';

const LinkButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: `transparent`,
  ...theme.typography.subtitle2,
  color: theme.palette.rexelEnergy.ctaBlue,
  padding: 0,
  minHeight: 0,
  minWidth: 0,
  height: `auto`,
  width: `auto`,
  display: `inline-block`,
  textAlign: `left`,
  marginRight: `auto`,
  '&:hover': {
    backgroundColor: `transparent`,
    color: theme.palette.rexelEnergy.selectedBlue,
  },
}));

export const LinkButton = ({ onClick, ...props }: ButtonProps) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  return <LinkButtonStyled {...props} onClick={handleClick} />;
};
