import { Card } from '@components/Card/Card';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Typography } from '@mui/material';
import { EditPartner } from '@pages/partners/partnerDataForms/EditPartner';

export interface PartnerCardProps {
  partner: PartnerDto | null;
}

export const PartnerCard = ({ partner }: PartnerCardProps) => {
  const {
    translate,
    translations: {
      partners: {
        details: { partnerHeader },
        create: { addressHeader, contactHeader },
      },
    },
  } = useTranslations();
  const [showEditPanel, toggleShowEditPanel] = useToggle();

  return (
    <Card title={translate(partnerHeader)} titleAction={toggleShowEditPanel} titleActionIcon={mdiPencil}>
      <Stack spacing={3}>
        <Stack>
          <Typography variant="body2">{partner?.name}</Typography>
          <Typography>{partner?.organizationNumber}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2">{translate(addressHeader)}</Typography>
          <Typography>{partner?.address?.street}</Typography>
          <Typography>
            {partner?.address?.zipCode} {partner?.address?.city}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body2">{translate(contactHeader)}</Typography>
          <Typography>{partner?.contactPerson}</Typography>
          <Typography>{partner?.email}</Typography>
          <Typography>{partner?.phoneNumber}</Typography>
        </Stack>
      </Stack>
      {partner && (
        <Drawer anchor="right" open={showEditPanel} onClose={toggleShowEditPanel}>
          <EditPartner partner={partner} onClose={toggleShowEditPanel} />
        </Drawer>
      )}
    </Card>
  );
};
