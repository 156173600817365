import React, { ChangeEventHandler, KeyboardEventHandler, MouseEventHandler, RefCallback, useMemo } from 'react';

import { SvgIcon } from '@components/controls/svgIcon';
import { InputErrorElement } from '@components/controls/input-error-element';
import IconButton from '@mui/material/IconButton';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiRefresh } from '@mdi/js';
import Stack from '@mui/material/Stack';
import './numeric.css';
import { NumericFormat } from 'react-number-format';
import { Box } from '@mui/material';

export const enum LABEL_POSITION {
  TOP = `TOP`,
  LEFT = `LEFT`,
  RIGHT = `RIGHT`,
  STRETCH_FULL_LINE = `STRETCH_FULL_LINE`,
}

export type NumericProps = {
  classesForContainer?: string;
  classesInput?: string;
  error?: string;
  id?: string;
  inputRef?: RefCallback<HTMLInputElement>;
  isDisabled?: boolean;
  label?: string;
  labelPosition?: LABEL_POSITION;
  max?: number;
  min?: number;
  name: string;
  onAdd: MouseEventHandler<HTMLButtonElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubtract: MouseEventHandler<HTMLButtonElement>;
  precision?: number;
  step?: number;
  suffix?: string;
  value: string | number;
  isRefreshable?: boolean;
  refresh?: () => void;
};

export const Numeric: React.FC<NumericProps> = ({
  classesForContainer,
  classesInput,
  error,
  id,
  isDisabled,
  label,
  labelPosition,
  max,
  min,
  name,
  onAdd,
  onChange,
  onSubtract,
  step,
  suffix,
  value,
  isRefreshable,
  refresh,
}) => {
  const labelPositionClass = useMemo(() => {
    switch (labelPosition) {
      case LABEL_POSITION.TOP:
        return `column`;
      case LABEL_POSITION.STRETCH_FULL_LINE:
        return `row no-wrap align-center space-between`;
      case LABEL_POSITION.LEFT:
        return `row align-center`;
      case LABEL_POSITION.RIGHT:
        return `row-reverse align-center`;
      default:
        return `row align-center`;
    }
  }, [labelPosition]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    [`e`, `+`, `-`].includes(event.key.toLocaleLowerCase()) && event.preventDefault();
  };

  return (
    <div className="validated-wrapper fw numeric-container">
      <div className={`${labelPositionClass} fw${error ?? ` error`}`}>
        {label && (
          <Stack direction="row" alignItems="center">
            <label data-testid="numeric-label" htmlFor={id || name}>
              {label}
            </label>
            {isRefreshable && (
              <IconButton onClick={refresh} sx={{ ml: -2 }}>
                <MdiIconWrapper path={mdiRefresh} />
              </IconButton>
            )}
          </Stack>
        )}
        <div className="numeric">
          <div className={`numeric-wrapper ${classesForContainer}`}>
            <button className="numeric-button" onClick={onSubtract} type="button" disabled={isDisabled}>
              <SvgIcon iconId="minus" svgClasses="option-icon" isDisabled={isDisabled} />
            </button>
            <Box
              sx={{
                width: `4.66rem`,
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <NumericFormat
                className={`numeric-input ${classesInput}`}
                disabled={isDisabled}
                id={id || name}
                max={max}
                min={min}
                name={name}
                onChange={onChange}
                step={step}
                value={`${value ?? 0}`}
                onKeyDown={handleKeyDown}
                decimalSeparator=","
                suffix={suffix && ` ${suffix}`}
              />
            </Box>
            <button className="numeric-button" onClick={onAdd} type="button" disabled={isDisabled}>
              <SvgIcon iconId="plus" svgClasses="option-icon" isDisabled={isDisabled} />
            </button>
          </div>
        </div>
      </div>
      {error && <InputErrorElement errorMsg={error} />}
    </div>
  );
};
