import { Root } from '@assets/translations/translations';
import translations from '@assets/translations/translations.json';
import { ViewType, VIEW_TYPES } from '@components/columnLayout/utils';
import { DialogProps } from '@components/dialog/dialog';
import { GlobalAction, GLOBAL_ACTIONS } from '@redux/actions/global';

const IMPLEMENTED_LANGUAGES = [`en`, `se`] as const;
const LOCAL_STORAGE_LANGUAGE_KEY = `CHOSEN_LANGUAGE`;
export type ImplementedLanguages = (typeof IMPLEMENTED_LANGUAGES)[number];

const stringIsImplementedLanguage = (input: string | null): input is ImplementedLanguages =>
  !!input && IMPLEMENTED_LANGUAGES.includes(input as ImplementedLanguages);

const localStorageLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);
export const defaultLanguage = `se`;
export const chosenLanguage =
  (stringIsImplementedLanguage(localStorageLanguage) && localStorageLanguage) || defaultLanguage;

export type GlobalState = {
  isMobileView: boolean;
  lastLocation: string;
  previousLocation: string;
  currentLeadID: number;
  currentPageView: ViewType;
  previousPageView: ViewType;
  translations: Root;
  defaultLanguage: typeof defaultLanguage;
  chosenLanguage: ImplementedLanguages;
  dialog: DialogProps;
};

export const initState: GlobalState = {
  isMobileView: false,
  lastLocation: `/`,
  previousLocation: `/`,
  currentLeadID: -1,
  currentPageView: VIEW_TYPES.LIST,
  previousPageView: VIEW_TYPES.LIST,
  translations: translations as Root,
  defaultLanguage,
  chosenLanguage,
  dialog: {
    isOpen: false,
    header: ``,
    content: ``,
  },
};

export const globalReducer = (state = initState, action: GlobalAction) => {
  switch (action.type) {
    case GLOBAL_ACTIONS.IS_MOBILE_VIEW: {
      return {
        ...state,
        isMobileView: action.payload.isMobileView ?? state.isMobileView,
      };
    }

    case GLOBAL_ACTIONS.LAST_LOCATION: {
      return {
        ...state,
        lastLocation: action.payload.lastLocation ?? state.lastLocation,
      };
    }

    case GLOBAL_ACTIONS.PREVIOUS_LOCATION: {
      return {
        ...state,
        previousLocation: action.payload.previousLocation ?? state.previousLocation,
      };
    }

    case GLOBAL_ACTIONS.CURRENT_LEAD_ID: {
      return {
        ...state,
        currentLeadID: action.payload.currentLeadID ?? state.currentLeadID,
      };
    }

    case GLOBAL_ACTIONS.CURRENT_PAGE_VIEW: {
      return {
        ...state,
        currentPageView: action.payload.currentPageView ?? state.currentPageView,
      };
    }

    case GLOBAL_ACTIONS.PREVIOUS_PAGE_VIEW: {
      return {
        ...state,
        previousPageView: action.payload.previousPageView ?? state.previousPageView,
      };
    }

    case GLOBAL_ACTIONS.DIALOG: {
      return {
        ...state,
        dialog: action.payload.dialog ?? state.dialog,
      };
    }

    default:
      return state;
  }
};
