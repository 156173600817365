import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ManageableProductRowDto } from '@generatedTypes/data-contracts';
import { useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';

type ProductSearchAutocompleteProps = {
  options: ManageableProductRowDto[];
  onProductSelect: (productId: number) => void;
};

export const ProductSearchAutocomplete: React.FC<ProductSearchAutocompleteProps> = ({ options, onProductSelect }) => {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { addEditForm },
      },
    },
  } = useTranslations();

  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [value, setValue] = useState<ManageableProductRowDto | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <div ref={ref} tabIndex={-1} />
      <Autocomplete
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Stack>
              <Typography noWrap>{option.name}</Typography>
              <Typography variant="subtitle2">{option.eNumber}</Typography>
            </Stack>
          </li>
        )}
        inputValue={inputValue ?? ``}
        value={value ?? null}
        onInputChange={(_, value) => setInputValue(value)}
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name + ` ` + option.eNumber}
        onChange={(_, value) => {
          value && onProductSelect(value?.id);
          setInputValue(undefined);
          setValue(null);
          ref.current?.focus();
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate(addEditForm.materialSearch)}
            InputProps={{ ...params.InputProps, endAdornment: null }}
            placeholder={translate(addEditForm.materialSearchPlaceholder)}
          />
        )}
      />
    </>
  );
};
