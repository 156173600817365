import { ColumnItem, VIEW_TYPES, showNext } from '@components/columnLayout/utils';
import { PartnerForm, getPartnerInitialValues, partnerDataZodObject } from './PartnerForm';
import { useCallback, useEffect, useMemo } from 'react';
import { parsePartnerForUpdate } from '@services/api/partners/utils';
import { parseZipCodeInputToPlainValue } from '@utils/parsers';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useUpdatePartner } from '@services/api/partners';
import { useQueryClient } from 'react-query';
import { PARTNER, PARTNERS } from '@variables/queryKeys';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormWrapper } from '@components/forms/MuiFormWrapper';

const partnerDataZodSchema = z.object(partnerDataZodObject);

export type PartnerDataValues = z.infer<typeof partnerDataZodSchema>;

type EditPartnerProps = {
  partner: PartnerDto;
  onClose: () => void;
};

export const EditPartner: ColumnItem<EditPartnerProps> = ({ partner, onClose }) => {
  const queryClient = useQueryClient();
  const updatePartner = useUpdatePartner({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNERS);
      queryClient.invalidateQueries(PARTNER);
      showNext(VIEW_TYPES.INFO)();
    },
  });

  const initialValues = useMemo(() => getPartnerInitialValues(partner), [partner]);

  const formFunctions = useForm<PartnerDataValues>({
    values: initialValues,
    resolver: zodResolver(partnerDataZodSchema),
  });
  const { handleSubmit, reset, setError } = formFunctions;

  const onSubmit = useCallback(
    (values: PartnerDataValues) => {
      updatePartner.mutate({
        ...parsePartnerForUpdate({
          ...partner,
          ...values,
        }),
        ...values,
        zipCode: parseZipCodeInputToPlainValue(values.zipCode) ?? ``,
      });
      onClose();
    },
    [onClose, partner, updatePartner],
  );

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (updatePartner.validationErrors) {
      Object.entries(updatePartner.validationErrors.errors).forEach(([key, error]) => {
        setError(key as keyof PartnerDataValues, { message: error[0] });
      });
    }
  }, [updatePartner.validationErrors, setError]);

  const disableForm = updatePartner.isLoading;

  return (
    <FormProvider {...formFunctions}>
      <FormWrapper onCancel={onCancel} onSubmit={handleSubmit(onSubmit)} disabled={disableForm}>
        <PartnerForm disableForm={disableForm} />
      </FormWrapper>
    </FormProvider>
  );
};
