import { Control, useFieldArray } from 'react-hook-form';
import { AddEditManageableMaterialOrPackageValues } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageForm';
import { useGetManageableProducts } from '@services/api/productManagement/products';
import { Box, Stack, Typography } from '@mui/material';
import { Numeric } from '@components/controls/react-hook-form-friendly/dumb';
import { ChangeEvent } from 'react';
import { FormSection } from '@components/forms/MuiFormSection';
import { useTranslations } from '@services/hooks/translations/useTranslations';

const ProductPackageItemRow = ({
  name,
  eNumber,
  quantity,
  onAdd,
  onChange,
  onSubtract,
}: {
  name: string;
  eNumber: number;
  quantity: number;
  onAdd: () => void;
  onSubtract: () => void;
  onChange: (value: number) => void;
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const number = parseInt(e.target.value);
    if (isNaN(number)) {
      onChange(0);
    }
    onChange(number);
  };

  return (
    <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`}>
      <Stack overflow="hidden">
        <Typography variant={`body1`} noWrap>
          {name}
        </Typography>
        <Typography variant={`subtitle1`}>{eNumber}</Typography>
      </Stack>
      <Box>
        <Numeric
          classesForContainer="small"
          name=""
          onAdd={onAdd}
          onChange={handleChange}
          onSubtract={onSubtract}
          value={quantity}
        />
      </Box>
    </Stack>
  );
};

type ProductPackageItemsList = {
  control: Control<AddEditManageableMaterialOrPackageValues>;
  listItems: AddEditManageableMaterialOrPackageValues[`productPackageItems`];
};

export function ProductPackageItemsSection({ control, listItems = [] }: ProductPackageItemsList) {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: {
          addEditForm: { productPackageItemsHeader },
        },
      },
    },
  } = useTranslations();
  const { remove, update } = useFieldArray<AddEditManageableMaterialOrPackageValues>({
    control,
    name: `productPackageItems`,
  });

  const { manageableProducts } = useGetManageableProducts();

  const productsToDisplay =
    listItems?.map((product) => {
      return manageableProducts.find((manageableProduct) => manageableProduct.id === product.productId);
    }) ?? [];

  const onAdd = (productIndex: number) => () => {
    const product = listItems.at(productIndex);
    if (product) {
      update(productIndex, {
        productId: product.productId,
        quantity: product.quantity + 1,
      });
    }
  };

  const onSubtract = (productIndex: number) => () => {
    const product = listItems.at(productIndex);
    if (product) {
      const newQuantity = product.quantity - 1;
      if (newQuantity > 0) {
        update(productIndex, {
          productId: product.productId,
          quantity: newQuantity,
        });
      } else {
        remove(productIndex);
      }
    }
  };

  const onChange = (productIndex: number) => (value: number) => {
    const product = listItems.at(productIndex);
    if (product) {
      const newQuantity = value < 0 ? 0 : value;
      update(productIndex, {
        productId: product.productId,
        quantity: newQuantity,
      });
    }
  };

  return (
    <FormSection title={translate(productPackageItemsHeader)}>
      <Stack spacing={1}>
        {productsToDisplay.map((product, index) => (
          <ProductPackageItemRow
            key={product?.id}
            name={product?.name ?? ``}
            eNumber={Number(product?.eNumber)}
            quantity={listItems.find((currentProduct) => currentProduct.productId === product?.id)?.quantity ?? 0}
            onAdd={onAdd(index)}
            onChange={onChange(index)}
            onSubtract={onSubtract(index)}
          />
        ))}
      </Stack>
    </FormSection>
  );
}
