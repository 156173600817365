import { authFetch } from '@services/api/utils';
import { ContactDataDto } from '@generatedTypes/data-contracts';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { CONTACT_DATA } from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import { selectSelectedDealerId } from '@redux/reducers/slices/dealerUser';

export const getContactData = async (dealerId?: number | null) => {
  const response = await authFetch(
    `${process.env.PROTECTED_API_URL}/contact-data${dealerId ? `?dealerId=${dealerId}` : ``}`,
    {
      method: `GET`,
      mode: `cors`,
    },
  );
  if (!response.ok) {
    throw new Error(`Contact data fetch failed`);
  }
  const json = await response.json();
  return json as ContactDataDto;
};

export const useGetContactData = () => {
  const selectedDealerId = useSelector(selectSelectedDealerId);
  const { data, isLoading } = useCustomQuery({
    queryKey: [CONTACT_DATA],
    queryFn: () => getContactData(selectedDealerId),
  });

  return { contactData: data, isContactDataLoading: isLoading };
};
