import { ActionWithConfirmation } from '@components/ActionWithConfirmation';
import { mdiTrashCanOutline } from '@mdi/js';

import { useTranslations } from '@services/hooks/translations/useTranslations';
import React from 'react';

type TextDeleteDialogProps = {
  onDelete: () => void;
  deleteButtonText?: string;
  confirmText?: string;
  cancelText?: string;
  iconPath?: string;
  dataTestId?: string;
  confirmationPopupTitle: string;
  confirmationPopupContent: string;
};

export const DeleteDialog: React.FC<TextDeleteDialogProps> = (props) => {
  const {
    onDelete,
    deleteButtonText,
    confirmText,
    cancelText,
    dataTestId,
    confirmationPopupTitle,
    confirmationPopupContent,
  } = props;

  const {
    translate,
    translations: {
      common: {
        buttons: { cancel, delete: defaultConfirmButtonText },
      },
    },
  } = useTranslations();

  const iconPath = props.iconPath ?? mdiTrashCanOutline;

  return (
    <ActionWithConfirmation
      buttonIconPath={iconPath}
      onConfirm={onDelete}
      confirmationPopupTitle={confirmationPopupTitle}
      confirmationPopupContent={confirmationPopupContent}
      dataTestId={dataTestId}
      confirmText={confirmText ?? translate(defaultConfirmButtonText)}
      cancelText={cancelText ?? translate(cancel)}
    >
      {deleteButtonText}
    </ActionWithConfirmation>
  );
};
