import { useGetPackageItems } from '@services/api/productPackages/productPackages';
import { PackageDetailsModal } from '@components/PackageDetailsModal/PackageDetailsModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectPackageNamesToDetailsModal, setPackageDataToDetailsModal } from '@redux/reducers/slices/leadsPage';

export const PackageDetailsModalFromStore = () => {
  const { packageItems, isLoadingPackageItems } = useGetPackageItems();
  const dispatch = useDispatch();
  const { packageName, categoryName } = useSelector(selectPackageNamesToDetailsModal);

  if (!isLoadingPackageItems && (!packageItems ?? packageItems?.length === 0)) {
    return null;
  }

  return (
    <PackageDetailsModal
      isOpen={true}
      onClose={() => {
        dispatch(setPackageDataToDetailsModal(null));
      }}
      categoryName={categoryName ?? ``}
      packageName={packageName ?? ``}
      products={packageItems ?? []}
      isLoading={isLoadingPackageItems}
    />
  );
};
