import { FC, ReactNode } from 'react';
import {
  closeSnackbar,
  MaterialDesignContent,
  SnackbarAction,
  SnackbarProvider as SnackbarProviderCore,
} from 'notistack';
import { styled } from '@mui/material/styles';
import { mdiCheckCircleOutline, mdiClose, mdiInformationOutline } from '@mdi/js';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { IconButton } from '@mui/material';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  // instruction if it will be needed to add more variants: https://notistack.com/features/customization
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.black,

    '& svg': {
      color: theme.palette.success.dark,
      marginRight: theme.spacing(1),
    },
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.black,

    '& svg': {
      color: theme.palette.error.dark,
      marginRight: theme.spacing(1),
    },
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.black,

    '& svg': {
      color: theme.palette.warning.dark,
      marginRight: theme.spacing(1),
    },
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.black,

    '& svg': {
      color: theme.palette.info.dark,
      marginRight: theme.spacing(1),
    },
  },
}));

const CloseSnackbarAction: SnackbarAction = (snackbarId) => (
  <IconButton
    onClick={() => {
      closeSnackbar(snackbarId);
    }}
  >
    <MdiIconWrapper color="black" path={mdiClose} />
  </IconButton>
);

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <SnackbarProviderCore
    anchorOrigin={{ horizontal: `center`, vertical: `bottom` }}
    variant="error"
    preventDuplicate
    maxSnack={5}
    Components={{
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
      info: StyledMaterialDesignContent,
    }}
    iconVariant={{
      success: <MdiIconWrapper path={mdiCheckCircleOutline} />,
      error: <MdiIconWrapper path={mdiInformationOutline} />,
      warning: <MdiIconWrapper path={mdiInformationOutline} />,
      info: <MdiIconWrapper path={mdiInformationOutline} />,
    }}
    action={CloseSnackbarAction}
  >
    {children}
  </SnackbarProviderCore>
);
