import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { setMapEditState } from '@redux/reducers/slices/leadsPage';
import { useDispatch } from 'react-redux';
import { LeadAddressDto } from '@generatedTypes/data-contracts';
import { useGetLatLngLiteralFromPosition } from '@components/maps/GoogleMaps/utils';
import { useHandleMaxZoom } from '@components/maps/GoogleMaps/useHandleMaxZoom';

const containerStyle = {
  width: `100%`,
  height: `100%`,
};

export const FOUND_LOCATION_ZOOM_LEVEL = 18;
const DEFAULT_MAP_VIEW_ANGLE = 0;

export type GoogleMapsCompProps = {
  children?: React.ReactNode;
  position:
    | Pick<LeadAddressDto, `latitude` | `longitude` | `indicatedLatitude` | `indicatedLongitude`>
    | google.maps.LatLngLiteral;
  disableMapUi?: boolean;
  onDragEnd?: (newPos: google.maps.LatLngLiteral) => void;
  MapHandlingComponent?: (props: { map: google.maps.Map | null }) => React.ReactNode;
  onMapLoad?: (map: google.maps.Map) => void;
  zoomLevel?: number;
};

const GoogleMapComp = ({
  disableMapUi,
  position,
  MapHandlingComponent,
  onDragEnd,
  children,
  onMapLoad,
  zoomLevel = FOUND_LOCATION_ZOOM_LEVEL,
}: GoogleMapsCompProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const mapRef = useRef<any>(null);

  useHandleMaxZoom({ map });

  const positionLatLng = useGetLatLngLiteralFromPosition(position);

  const bounds = useMemo(() => new google.maps.LatLngBounds(positionLatLng), [positionLatLng]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMapEditState(`VIEW`));
  }, [positionLatLng, dispatch]);

  useEffect(() => {
    if (map && positionLatLng) {
      map.panTo(positionLatLng);
    }
  }, [map, positionLatLng]);

  const onLoad = useCallback(
    function callback(map: google.maps.Map) {
      mapRef.current = map;
      map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      map.setTilt(DEFAULT_MAP_VIEW_ANGLE);
      map.setZoom(zoomLevel);
      map.setCenter(positionLatLng);

      setMap(map);
      onMapLoad?.(map);
    },
    [onMapLoad, positionLatLng, zoomLevel],
  );
  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const handleZoomChanged = () => {
    if (!mapRef.current) return;
    positionLatLng && mapRef.current?.setCenter(positionLatLng);
    bounds && mapRef.current?.panToBounds(bounds);
  };

  const handleDragEnd = () => {
    const newPos = map?.getCenter()?.toJSON();
    if (newPos && onDragEnd) {
      onDragEnd(newPos);
    }
  };

  const googleMapOptions: google.maps.MapOptions = useMemo(
    () => ({
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      draggable: true,
      gestureHandling: `cooperative`,
      disableDefaultUI: disableMapUi,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
    }),
    [disableMapUi],
  );

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={handleZoomChanged}
      options={googleMapOptions}
      ref={mapRef}
      onDragEnd={handleDragEnd}
    >
      {MapHandlingComponent && <MapHandlingComponent map={map} />}
      {children}
    </GoogleMap>
  );
};

export const GoogleMaps = memo(GoogleMapComp);
