import {
  ProductAttributeRowListProps,
  ProductAttributeRowNumberProps,
  ProductsAttributeFiltersSchemaType,
} from './types';
import {
  ProductAttributeFilterType,
  ProductAttributeRowDto,
  ProductCategoryRowDto,
} from '@generatedTypes/data-contracts';
import { getFormFilterForNumberTypeFilter } from './numberType/utils';
import { getFormFilterForListTypeFilter } from './listType/utils';

const isFilterOfId =
  (idToCompare: number) =>
  ({ attributeId }: ProductsAttributeFiltersSchemaType[number]): boolean =>
    attributeId === idToCompare;

const getFormFilterForAttribute = ({
  attribute,
  valuesAvailableForAttribute,
  appliedFilter,
  initialFilter,
}: {
  attribute: ProductAttributeRowDto;
  valuesAvailableForAttribute: Set<number> | undefined;
  appliedFilter?: ProductAttributeRowListProps | ProductAttributeRowNumberProps;
  initialFilter?: ProductAttributeRowListProps | ProductAttributeRowNumberProps;
}): ProductsAttributeFiltersSchemaType[number] | null => {
  if (attribute.filterType === ProductAttributeFilterType.List) {
    return getFormFilterForListTypeFilter({
      attribute,
      appliedFilter: appliedFilter as ProductAttributeRowListProps,
      initialFilter: initialFilter as ProductAttributeRowListProps,
      valuesAvailableForAttribute,
    });
  }
  if (attribute.filterType === ProductAttributeFilterType.Number) {
    return getFormFilterForNumberTypeFilter({
      attribute,
      appliedFilter: appliedFilter as ProductAttributeRowNumberProps,
      initialFilter: initialFilter as ProductAttributeRowNumberProps,
      valuesAvailableForAttribute,
    });
  }
  return null;
};

type GetFieldValuesProps = {
  productCategory: ProductCategoryRowDto;
  attributesMap: Map<number, Set<number>>;
  initialFilters: ProductsAttributeFiltersSchemaType;
  appliedFilters: ProductsAttributeFiltersSchemaType;
};
export const getFieldValues = ({
  productCategory,
  initialFilters,
  appliedFilters,
  attributesMap,
}: GetFieldValuesProps): ProductsAttributeFiltersSchemaType =>
  productCategory.productsAttributes?.reduce<ProductsAttributeFiltersSchemaType>((formFilters, attribute) => {
    if (attribute.id) {
      const valuesAvailableForAttribute = attributesMap.get(attribute.id);
      const initialFilter = initialFilters.find(isFilterOfId(attribute.id));
      const filterFromApplied = appliedFilters.find(isFilterOfId(attribute.id));
      const formFilter = getFormFilterForAttribute({
        attribute,
        initialFilter,
        appliedFilter: filterFromApplied,
        valuesAvailableForAttribute,
      });
      if (formFilter) {
        formFilters.push(formFilter);
      }
    }
    return formFilters;
  }, [] as ProductsAttributeFiltersSchemaType) ?? [];
