import {
  CustomSolarPanelDto,
  LeadAddressDto,
  OfferRoofDto,
  OfferRoofPatchDto,
  ProductAttributeValueRowDto,
  RoofDto,
  RoofPatchDto,
  RoofPatchShape,
  RoofPatchShapeVertex,
  SolarEnergyProjectDto,
  SolarPanelManufacturerAttributeValueDto,
  TerrainTypeAttributeValueDto,
} from '@generatedTypes/data-contracts';
import { uuid } from 'short-uuid';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { GetOrientationForPatch } from './useSolarMapVisualisation/useSolarMapVisualisation';
import { Patch, Roof, Vertex } from '../roofVisualisationTypes';
import { mmToM } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/calculations';
import { Option } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { CURRENT_PANELS_RESOLVER_VERSION } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/panelsResolver/panelsResolver';
import { PANEL_SIZE } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/constants';
import { CUSTOM_SOLAR_PANEL_MANUFACTURER } from '@pages/NewLeads/project/solarEnergyProject/utils/constants';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';

const getVertices = (vertices: RoofPatchShape): Vertex[] => {
  const values: RoofPatchShapeVertex[] = Object.values(vertices).filter(
    (vertex) =>
      vertex && typeof vertex === `object` && Object.hasOwn(vertex, `latitude`) && Object.hasOwn(vertex, `longitude`),
  );

  return values.map(({ latitude, longitude }) => ({
    id: uuid(),
    selected: false,
    latLng: new window.google.maps.LatLng({ lat: latitude ?? 0, lng: longitude ?? 0 }),
  }));
};

interface GetPatches {
  patches: RoofPatchDto[] | OfferRoofPatchDto[];
  roofId: string;
  getOrientationForPatch?: GetOrientationForPatch;
  roofIndex: number;
}

const getPatches = ({ patches, roofId, roofIndex, getOrientationForPatch }: GetPatches): Patch[] => {
  return patches.map((patch, patchIndex) => {
    const createdPatch: Patch = {
      id: `${roofId}-${patch.id}`,
      selected: false,
      edges: [],
      panels:
        patch.solarPanelGrid?.map(({ isActive, isInShape }, index) => ({
          active: isActive,
          id: index,
          insideShape: isInShape,
        })) || [],
      width: 0,
      height: 0,
      columns: 0,
      rows: 0,
      vertices: patch.shape ? getVertices(patch.shape) : [],
      solarEnergyProduction: Object.hasOwn(patch, `solarEnergyProduction`)
        ? (patch as RoofPatchDto).solarEnergyProduction
        : {
            yearlyProduction: 0,
            peakPower: 0,
            yearlyProductionInkWh: 0,
          },
      panelOrientation:
        (Object.hasOwn(patch, `solarPanelOrientation`) && (patch as OfferRoofPatchDto).solarPanelOrientation) ||
        getOrientationForPatch?.(roofIndex, patchIndex) ||
        null,
    };
    return createdPatch;
  });
};

const getPanelSizeFromSettings = ({
  sizeId,
  settingValues,
}: {
  sizeId: number;
  settingValues: ProductAttributeValueRowDto[];
}): number => Number(settingValues?.find(({ id }) => id === sizeId)?.name) || 0;

const getPanelSize = ({
  solarPanelManufacturers,
  solarPanelWidthId,
  solarPanelHeightId,
  solarPanelWidth,
  solarPanelHeight,
  customSolar,
  panelsWidthSettings,
  panelsHeightSettings,
  solarPanelManufacturerId,
}: {
  solarPanelWidthId: number;
  solarPanelHeightId: number;
  solarPanelWidth?: number | null;
  solarPanelHeight?: number | null;
  customSolar?: CustomSolarPanelDto | null;
  solarPanelManufacturerId: number;
  panelsWidthSettings: ProductAttributeValueRowDto[];
  panelsHeightSettings: ProductAttributeValueRowDto[];
  solarPanelManufacturers?: SolarPanelManufacturerAttributeValueDto[];
}): { panelWidth: number; panelHeight: number } => {
  if (solarPanelWidth && solarPanelHeight) {
    return {
      panelWidth: solarPanelWidth,
      panelHeight: solarPanelHeight,
    };
  }
  if (solarPanelHeightId && solarPanelWidthId) {
    return {
      panelWidth: getPanelSizeFromSettings({ sizeId: solarPanelWidthId, settingValues: panelsWidthSettings }),
      panelHeight: getPanelSizeFromSettings({ sizeId: solarPanelHeightId, settingValues: panelsHeightSettings }),
    };
  }
  if (customSolar?.height && customSolar?.width) {
    return {
      panelWidth: customSolar.width || 0,
      panelHeight: customSolar.height || 0,
    };
  }
  if (solarPanelManufacturerId && panelsWidthSettings && panelsHeightSettings) {
    const manufacturer = solarPanelManufacturers?.find(({ id }) => id === solarPanelManufacturerId);
    const { widthId, heightId } = manufacturer?.availableSolarPanelSizes.at(0) ?? { widthId: 0, heightId: 0 };
    if (widthId && heightId) {
      return {
        panelWidth: getPanelSizeFromSettings({ sizeId: widthId, settingValues: panelsWidthSettings }),
        panelHeight: getPanelSizeFromSettings({ sizeId: heightId, settingValues: panelsHeightSettings }),
      };
    }
  }
  return {
    panelWidth: PANEL_SIZE.width,
    panelHeight: PANEL_SIZE.height,
  };
};

interface GetRoofs {
  roofs: OfferRoofDto[] | RoofDto[];
  getOrientationForPatch?: GetOrientationForPatch;
  panelsWidthSettings?: ProductAttributeValueRowDto[];
  panelsHeightSettings?: ProductAttributeValueRowDto[];
  solarPanelManufacturers?: SolarPanelManufacturerAttributeValueDto[];
}

export const getRoofs = ({
  roofs = [],
  getOrientationForPatch,
  panelsHeightSettings,
  panelsWidthSettings,
  solarPanelManufacturers,
}: GetRoofs): Roof[] => {
  return roofs.map(
    ({ id, patches, solarPanelHeightId, solarPanelWidthId, customSolar, ...restRoofProperties }, roofIndex) => {
      const roofId = String(id);
      return {
        id: roofId,
        patches: patches ? getPatches({ patches, roofId, getOrientationForPatch, roofIndex }) : [],
        selected: false,
        ...getPanelSize({
          solarPanelHeightId: solarPanelHeightId ?? 0,
          solarPanelWidthId: solarPanelWidthId ?? 0,
          solarPanelWidth: (restRoofProperties as Partial<OfferRoofDto>)?.solarPanelWidth,
          solarPanelHeight: (restRoofProperties as Partial<OfferRoofDto>)?.solarPanelHeight,
          customSolar,
          panelsWidthSettings: panelsWidthSettings || [],
          panelsHeightSettings: panelsHeightSettings || [],
          solarPanelManufacturers,
          solarPanelManufacturerId: restRoofProperties.solarPanelManufacturerId ?? 0,
        }),
        ...restRoofProperties,
      } as Roof;
    },
  );
};

// TODO: we have to force someone to fix the backend types so they stop beeing optional
// FIXME: remove that mappings after changing keys to stop being optional
const getInitialFormPatches = (roofPatch: RoofPatchDto[]): SolarEnergyProjectValues[`roofs`][number][`patches`] =>
  roofPatch.map(
    ({
      id,
      angle,
      direction,
      shape,
      solarPanelGrid,
      solarPanelOrientationId,
      is90Degrees,
      numberOfRailsId,
      railOrientationId,
      width,
      height,
      numberOfRows,
      numberOfColumns,
    }) => ({
      id: String(id),
      angle: angle as number | null,
      direction: direction as number | null,
      shape,
      solarPanelGrid,
      solarPanelOrientationId: solarPanelOrientationId as number | null,
      shapeType: is90Degrees ? `straight` : `free`,
      numberOfRailsId,
      railOrientationId,
      width,
      height,
      numberOfRows,
      numberOfColumns,
    }),
  ) as SolarEnergyProjectValues[`roofs`][number][`patches`];

const getInitialFormRoofs = (roofs: RoofDto[]): SolarEnergyProjectValues[`roofs`] => {
  const { solarPanelManufacturer } = useGetSolarEnergyProjectSettings();
  return roofs.map((roof) => ({
    id: String(roof.id),
    name: roof.name ?? ``,
    patches: roof.patches ? getInitialFormPatches(roof.patches) : [],
    solarPanelManufacturerId:
      roof.solarPanelManufacturerId ??
      (roof.customSolar ? CUSTOM_SOLAR_PANEL_MANUFACTURER : solarPanelManufacturer?.values?.at(0)?.id ?? 0),
    solarPanelSize:
      roof.solarPanelWidthId && roof.solarPanelHeightId
        ? [roof.solarPanelWidthId, roof.solarPanelHeightId].join(`,`)
        : null,
    roofMaterialId: roof.roofMaterialId ?? null,
    mountingMaterialManufacturerId: roof.mountingMaterialManufacturerId ?? 0,
    roofTypeId: roof.roofTypeId ?? null,
    ridgeHeight: mmToM(roof.ridgeHeight ?? 0),
    snowProtection: roof.snowProtection ?? null,
    tinOrMetalThicknessId: roof.tinOrMetalThicknessId ?? null,
    solarPanelAttachmentId: roof.solarPanelAttachmentId ?? null,
    lathDimensionId: roof.lathDimensionId ?? 0,
    tileHeight: roof.tileHeight ?? null,
    tileWidth: roof.tileWidth ?? null,
    distanceBetweenTopsTinOrMetal: roof.distanceBetweenTopsTinOrMetal ?? null,
    distanceBetweenFolds: roof.distanceBetweenFolds ?? null,
    customSolarPanel: {
      ...roof.customSolar,
    },
  }));
};

export const useGetInitialRoofsForForm = ({
  windLoadOptions,
  snowLoadOptions,
  templateOptionsData,
  address,
  projectDetails,
  terrainTypeValues,
}: {
  projectDetails: SolarEnergyProjectDto | null;
  templateOptionsData: { label: string; value: number }[];
  address?: LeadAddressDto;
  windLoadOptions: Option<number>[];
  snowLoadOptions: Option<number>[];
  terrainTypeValues: TerrainTypeAttributeValueDto[];
}): SolarEnergyProjectValues | undefined => {
  if (!projectDetails) {
    if (!address) {
      return;
    }
    return {
      comment: ``,
      projectProductTemplateId: templateOptionsData[0].value,
      roofs: [],
      panelsResolverVersion: CURRENT_PANELS_RESOLVER_VERSION,
      terrainTypeId: terrainTypeValues[0].id,
      snowLoadId: address?.snowLoad || snowLoadOptions[0].value,
      windLoadId: address?.windLoad || windLoadOptions[0].value,
    };
  }

  const { roofs, projectProductTemplateId, comment, ...rest } = projectDetails;

  return {
    ...rest,
    comment: comment ?? ``,
    projectProductTemplateId: projectProductTemplateId || templateOptionsData[0].value,
    roofs: getInitialFormRoofs(roofs),
    snowLoadId: address?.snowLoad ?? snowLoadOptions[0].value,
    windLoadId: address?.windLoad ?? windLoadOptions[0].value,
  };
};
