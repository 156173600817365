import { DataGridProps, GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { DataGridStyled } from '@components/Table/Table.styled';
import { Stack } from '@mui/material';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/dumb';
import { filterRows } from '@components/Table/utils';
import { useState } from 'react';

export type BasicRowType = string | number | boolean | undefined | null;

export type RowType = GridValidRowModel & {
  [p: string]: BasicRowType;
  [p: symbol]: BasicRowType;
  disabled?: boolean;
  id: string | number;
};

export type TableProps<Row extends RowType> = DataGridProps & {
  filterOn?: Omit<keyof Row, `disabled`>[];
  filterOnPlaceholder?: string;
  columns: GridColDef<Row>[];
  rows: Row[];
};

export function Table<Row extends RowType>({ columns, rows, filterOn, filterOnPlaceholder, ...rest }: TableProps<Row>) {
  const [filter, setFilter] = useState<string>(``);

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <Stack>
      {filterOn && (
        <InputWrapper label="" name="" placeholder={`${filterOnPlaceholder ?? ``}`} onChange={onFilterChange} />
      )}
      <DataGridStyled
        rows={filterOn ? filterRows(rows, filter, filterOn) : rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 30,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20, 30, 50, 100]}
        disableRowSelectionOnClick
        autoHeight
        {...rest}
      />
    </Stack>
  );
}
