import { RowType } from '@components/Table/Table';

export const filterRows = <Row extends RowType>(
  rows: Row[],
  filter: string,
  filterOn: Omit<keyof Row, `disabled`>[],
) => {
  return rows.filter((row) => {
    return filterOn.some((key) => {
      return String(row[`${key}`]).toLowerCase().includes(filter.toLowerCase());
    });
  });
};
