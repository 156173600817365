import { styled } from '@mui/material/styles';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';

type ListItemButtonProps = {
  interactive?: boolean;
};

type BorderedListItem = {
  backgroundColor?: string;
};

export const BorderedListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== `backgroundColor`,
})<BorderedListItem>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor ?? `transparent`,
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(1),
  },
}));

export const ListItemButtonStyled = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== `interactive`,
})<ListItemButtonProps>(({ interactive }) => ({
  '&:hover': !interactive && {
    backgroundColor: `transparent`,
    cursor: `default`,
  },
}));

export const ListItemButtonBorder = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== `interactive`,
})<ListItemButtonProps>(({ theme, interactive }) => ({
  border: `1px solid ${theme.palette.rexelEnergy.lightGrey}`,
  minHeight: theme.spacing(8),
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(1),
  },

  '& svg': {
    color: theme.palette.primary.dark,
  },

  '&.Mui-selected': {
    background: `transparent`,
    border: `1px solid ${theme.palette.primary.dark}`,
    outline: `1px solid ${theme.palette.primary.dark}`,

    '&:hover': interactive
      ? {
          background: `transparent`,
          border: `1px solid ${theme.palette.primary.dark}`,
          outline: `1px solid ${theme.palette.primary.dark}`,
        }
      : {
          background: `transparent`,
          cursor: `default`,
        },
  },

  '&:hover': interactive
    ? {
        background: `transparent`,
        color: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.rexelEnergy.ctaBlue}`,
        outline: `1px solid ${theme.palette.rexelEnergy.ctaBlue}`,

        '& svg': {
          color: theme.palette.primary.dark,
        },
      }
    : {
        background: `transparent`,
        cursor: `default`,
      },

  '&.Mui-disabled': {
    opacity: 1,
    borderColor: theme.palette.rexelEnergy.lightGrey,
    backgroundColor: theme.palette.background.default,
    color: `black`,
  },

  '&.Mui-disabled.Mui-selected': {
    border: `1px solid ${theme.palette.primary.dark}`,
    outline: `1px solid ${theme.palette.primary.dark}`,
  },
}));

export interface ListItemIconProps {
  isOnTop?: boolean;
}
