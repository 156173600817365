import { ProductAttributeFilterType, ProductAttributeRowDto } from '@generatedTypes/data-contracts';
import { ProductAttributeRowListProps, ProductsAttributeFiltersSchemaType } from '../types';

const valueExistsWithinOptions =
  (valueToFind: number | undefined) =>
  ({ value }: { value: number }) =>
    String(value) === String(valueToFind);

type GetOptionsForAttributeProps = {
  attribute: ProductAttributeRowDto;
  valuesAvailableForAttribute: Set<number> | undefined;
  appliedFilter?: ProductAttributeRowListProps;
};
// return the list of options for a given attribute, with the disabled flag set to true for options that don't have any products with that attribute value
const getOptionsForAttribute = ({
  valuesAvailableForAttribute,
  attribute,
  appliedFilter,
}: GetOptionsForAttributeProps) =>
  attribute?.values?.map(({ name, id }) => ({
    ...((appliedFilter as ProductAttributeRowListProps)?.options?.find(({ value }) => value === id) ?? {}),
    value: id ?? ``,
    label: name ?? ``,
    disabled: !id || !valuesAvailableForAttribute || !valuesAvailableForAttribute.has(id),
  })) ?? [];

type GetValueForAttributeProps = {
  optionsForAttribute: { value: number; label: string; disabled: boolean }[];
  appliedFilter?: ProductAttributeRowListProps;
  initialFilter?: ProductAttributeRowListProps;
};

// returns a value for a given attribute.
// if the applied filter has a value, and that value is available for the attribute, return that value
// if the value from the applied filter is not available for the attribute, return first available value
const getValueAndNameForAttribute = ({
  optionsForAttribute,
  appliedFilter,
  initialFilter,
}: GetValueForAttributeProps) => {
  const availableOptions = optionsForAttribute.filter(({ disabled }) => !disabled);
  return (
    availableOptions.find(valueExistsWithinOptions(appliedFilter?.value)) ??
    availableOptions.find(valueExistsWithinOptions(initialFilter?.value)) ??
    availableOptions[0] ??
    -1
  );
};

type GetFieldValuesProps = {
  attribute: ProductAttributeRowDto;
  valuesAvailableForAttribute: Set<number> | undefined;
  appliedFilter?: ProductAttributeRowListProps;
  initialFilter?: ProductAttributeRowListProps;
};
export const getFormFilterForListTypeFilter = ({
  attribute,
  appliedFilter,
  valuesAvailableForAttribute,
  initialFilter,
}: GetFieldValuesProps): ProductsAttributeFiltersSchemaType[number] => {
  const optionsForAttribute = getOptionsForAttribute({ valuesAvailableForAttribute, appliedFilter, attribute }) ?? [];
  const { value, label } = getValueAndNameForAttribute({ optionsForAttribute, appliedFilter, initialFilter });
  return {
    type: ProductAttributeFilterType.List,
    attributeName: attribute.name ?? ``,
    active: appliedFilter?.active ?? false,
    attributeId: attribute.id,
    value,
    valueName: label,
    options: optionsForAttribute,
  };
};

export const testListAttributeWithFilters = ({
  attribute,
  filters,
}: {
  attribute: ProductAttributeRowDto;
  filters: ProductsAttributeFiltersSchemaType;
}) => filters.every(({ value }) => attribute?.values?.map(({ id }) => id).includes(Number(value)));
