import {
  AdditionPriceDto,
  DeductionType,
  OfferPriceOverviewDto,
  UpdateOfferPriceOverviewRequest,
} from '@generatedTypes/data-contracts';
import { UseFormSetValue } from 'react-hook-form';
import { OfferPriceOverviewSchemaType } from './OfferPriceOverviewPage';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';

const BASE_CALCULATION_PRECISION = 100;
const VAT_FACTOR = 0.25;

export const getCalculatedPrices = (totalNetPrice?: number, totalNetPriceRounding: number | string = 0) => {
  const roundingAsParsedInput = parseInputValueToSubmitNumberValue(totalNetPriceRounding);
  const parsedRounding = isNaN(Number(roundingAsParsedInput)) ? 0 : Number(roundingAsParsedInput);
  const unifiedTotalNetPrice = (totalNetPrice ?? 0) * BASE_CALCULATION_PRECISION;
  const unifiedPriceRounding = parsedRounding * BASE_CALCULATION_PRECISION;

  const calculatedVatPrice = Math.round(unifiedTotalNetPrice * VAT_FACTOR);
  const calculatedNetWithVat = unifiedTotalNetPrice + calculatedVatPrice + unifiedPriceRounding;

  return {
    totalNetPrice: Number((unifiedTotalNetPrice / BASE_CALCULATION_PRECISION).toFixed(2)),
    calculatedVatPrice: Number((calculatedVatPrice / BASE_CALCULATION_PRECISION).toFixed(2)),
    netPriceWithVat: Number((calculatedNetWithVat / BASE_CALCULATION_PRECISION).toFixed(2)),
  };
};

export const parsePriceOverviewValuesForAPI = (
  { bids, totalGrossPriceRounding, shouldResetRounding }: OfferPriceOverviewSchemaType,
  isRoundingDirty?: boolean,
): UpdateOfferPriceOverviewRequest => {
  return {
    bids: bids.map(({ id, margin }) => ({
      id,
      margin,
    })),
    priceRounding: shouldResetRounding
      ? null
      : isRoundingDirty
        ? parseInputValueToSubmitNumberValue(totalGrossPriceRounding)
        : 0,
  };
};

export const getDraftUrl = (offerId: string) => `/offer/${offerId}`;

export type DeductionOptionType = {
  value: keyof typeof DeductionType;
  label: string;
};

export const divideToDeductibleAndNonDeductibleCosts = (bids: AdditionPriceDto[]) => {
  const dividedBids: { deductible: AdditionPriceDto[]; nonDeductible: AdditionPriceDto[] } = {
    deductible: [],
    nonDeductible: [],
  };

  for (const bid of bids) {
    if (bid.isDeductible) {
      dividedBids.deductible.push(bid);
    } else {
      dividedBids.nonDeductible.push(bid);
    }
  }

  return dividedBids;
};

export const getNewPricesAfterCalculation = (
  initialOfferPrice: OfferPriceOverviewDto | null,
  calculatedOfferPrice: OfferPriceOverviewDto | null | undefined,
  currentValues: OfferPriceOverviewSchemaType | null,
): OfferPriceOverviewSchemaType | null => {
  if (!initialOfferPrice) {
    return null;
  }
  const newBids: OfferPriceOverviewSchemaType[`bids`] =
    initialOfferPrice?.bids?.map((bid, index) => {
      const { netPrice, totalNetPriceWithoutDeduction, id, margin } =
        calculatedOfferPrice?.bids.find(({ id }) => bid.id === id) ?? bid;
      return {
        netPrice,
        totalNetPriceWithoutDeduction,
        id,
        margin: currentValues?.bids[index]?.margin ?? margin.value,
        calculatedMargin: margin.calculatedValue,
      };
    }) ?? [];

  return {
    totalGrossPriceRounding:
      calculatedOfferPrice?.totalGrossPriceRounding ??
      currentValues?.totalGrossPriceRounding ??
      initialOfferPrice.totalGrossPriceRounding,
    totalNetPrice: calculatedOfferPrice?.totalNetPrice ?? initialOfferPrice.totalNetPrice,
    bids: newBids,
    shouldResetRounding: currentValues?.shouldResetRounding ?? false,
  };
};

export const updateOfferPriceOverviewFormWithCalculation = (
  calculationData: OfferPriceOverviewSchemaType | null | undefined,
  setValue: UseFormSetValue<OfferPriceOverviewSchemaType>,
  isRoundingDirty?: boolean,
) => {
  if (!calculationData) {
    return;
  }
  if (!isRoundingDirty) {
    setValue(`totalGrossPriceRounding`, calculationData.totalGrossPriceRounding);
  }
  setValue(`totalNetPrice`, calculationData.totalNetPrice);
  setValue(`bids`, calculationData.bids);
};
