import { Button, Stack, Typography } from '@mui/material';
import { DeductionTemplateDto } from '@generatedTypes/data-contracts';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { CheckboxList } from '@components/controls/react-hook-form-friendly/smart';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { zodResolver } from '@hookform/resolvers/zod';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiPlus } from '@mdi/js';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { getMapDeductionTemplateToCheckboxRow } from './getMapDeductionTemplateToCheckboxRow';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useMemo } from 'react';
import { EmptyTemplateList } from '@components/Empty/EmptyTemplateList';

const ProjectDeductionTemplateSelectionZodObject = {
  templateIds: z.array(z.number()),
};

const ProjectDeductionTemplateSelectionZodSchema = z.object(ProjectDeductionTemplateSelectionZodObject);

export type ProjectDeductionTemplateSelectionValuesType = z.infer<typeof ProjectDeductionTemplateSelectionZodSchema>;

type ProjectDeductionTemplateSelectionProps = {
  templates: DeductionTemplateDto[];
  onClickNewDeduction: () => void;
  onSubmitTemplates: (templateIds: number[]) => void;
  onClose: () => void;
};

export const ProjectDeductionTemplateSelection: React.FC<ProjectDeductionTemplateSelectionProps> = ({
  onSubmitTemplates,
  onClickNewDeduction,
  templates,
  onClose,
}) => {
  const {
    translate,
    translations: {
      common: { units },
      leads: {
        details: {
          project: {
            deductionTemplateSelection: {
              newDeductionButton,
              header,
              chooseTemplateHeader,
              noTemplatesButton,
              noTemplatesDescription,
            },
          },
        },
      },
    },
  } = useTranslations();
  const { control, handleSubmit } = useForm<ProjectDeductionTemplateSelectionValuesType>({
    resolver: zodResolver(ProjectDeductionTemplateSelectionZodSchema),
    defaultValues: { templateIds: [] },
  });

  const handleOnSubmit = (data: ProjectDeductionTemplateSelectionValuesType) => {
    onSubmitTemplates(data.templateIds);
  };

  const mapDeductionsTemplateToCheckboxRow = useMemo(
    () =>
      getMapDeductionTemplateToCheckboxRow({
        currencyTranslation: translate(units.currencyCrowns),
      }),
    [translate, units],
  );

  return (
    <FormWrapper
      onSubmit={handleSubmit(handleOnSubmit)}
      onCancel={onClose}
      title={translate(header)}
      titleVariant="h2"
      titleAction={onClose}
    >
      <Stack spacing={4}>
        <Stack gap={2}>
          <Button
            variant="text"
            startIcon={<MdiIconWrapper path={mdiPlus} />}
            onClick={onClickNewDeduction}
            sx={{ justifyContent: `flex-start` }}
          >
            {translate(newDeductionButton)}
          </Button>
          <HorizontalLine />
        </Stack>

        {templates.length ? (
          <>
            <Typography variant="h3">{translate(chooseTemplateHeader)}</Typography>
            <Stack spacing={2}>
              <CheckboxList
                control={control}
                name="templateIds"
                isLabelOnRight
                options={templates.map(mapDeductionsTemplateToCheckboxRow)}
              />
            </Stack>
          </>
        ) : (
          <EmptyTemplateList noTemplatesDescription={noTemplatesDescription} noTemplatesButton={noTemplatesButton} />
        )}
      </Stack>
    </FormWrapper>
  );
};
