import { ProductPackageItemDto } from '@generatedTypes/data-contracts';
import { PackageDetailsModal } from '@components/PackageDetailsModal/PackageDetailsModal';

export type OfferAgreementPackageDetailsProps = {
  packageName: string;
  packageCategoryName: string;
  products: ProductPackageItemDto[];
};

type PackageDetailsModalFromOfferAgreementProps = {
  packageDetails: OfferAgreementPackageDetailsProps | null;
  onClose: () => void;
};

export const PackageDetailsModalFromOfferAgreement = ({
  packageDetails,
  onClose,
}: PackageDetailsModalFromOfferAgreementProps) => {
  if (!packageDetails) {
    return null;
  }

  const { packageCategoryName, packageName, products } = packageDetails;

  return (
    <PackageDetailsModal
      isOpen={true}
      onClose={onClose}
      categoryName={packageCategoryName}
      packageName={packageName}
      products={products}
      isLoading={false}
    />
  );
};
