import { ColumnLayout } from '@components/Layout/ColumnLayout/ColumnLayout';
import { LeftNavLinkLoaderList } from '@components/LeftNavLink/LeftNavLinkLoaderList';
import { LeftNavLinkTwoRows } from '@components/LeftNavLink/LeftNavLinkTwoRows';
import { useToggle } from '@hooks/useToggle';
import Drawer from '@mui/material/Drawer';
import { useExportPartners, useGetPartners } from '@services/api/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Outlet, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ExportDropdown } from '@components/Dropdown/ExportDropdown';
import { UserTypeBaseRenderer } from '@components/helpers/UserTypeBaseRenderer';
import { NewPartnerForm } from '@pages/partners/partnerDataForms/NewPartner';

export const PARTNER_EDIT_VIEWS = {
  createPartner: `create-partner`,
  editPartner: `edit-partner`,
  newUser: `new-user`,
  editTermsAndConditions: `edit-tac`,
  editClientInformation: `edit-client-information`,
} as const;

const PartnersPageTitle = () => {
  const {
    translate,
    translations: {
      partners: {
        list: { header, exportButton },
      },
    },
  } = useTranslations();

  const { exportPartners } = useExportPartners();

  return (
    <Stack direction="row" alignItems="center">
      <Typography ml={2} variant="h3">
        {translate(header)}
      </Typography>
      <UserTypeBaseRenderer include={[`Operation`]}>
        <ExportDropdown exportButtonText={translate(exportButton)} exportButtonAction={exportPartners} />
      </UserTypeBaseRenderer>
    </Stack>
  );
};

export const PartnersPage = () => {
  const { partnerId } = useParams<{ partnerId: string }>();

  const { partners, isFetchingPartners } = useGetPartners();
  const [isNewPartnerFormOpen, toggleIsNewPartnerOpen] = useToggle();

  return (
    <ColumnLayout
      onNewClick={toggleIsNewPartnerOpen}
      title={<PartnersPageTitle />}
      list={
        isFetchingPartners
          ? LeftNavLinkLoaderList
          : partners.map(({ id, name, city, organizationNumber }) => (
              <LeftNavLinkTwoRows
                selected={Number(partnerId) === id}
                key={id}
                title={name}
                href={`/partners/${id}`}
                leftBottom={organizationNumber}
                rightTop={city}
              />
            ))
      }
    >
      <Outlet />
      <Drawer open={isNewPartnerFormOpen} anchor="right" onClose={toggleIsNewPartnerOpen}>
        <NewPartnerForm onClose={toggleIsNewPartnerOpen} />
      </Drawer>
    </ColumnLayout>
  );
};
