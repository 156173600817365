import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { z } from 'zod';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { OptionWithAvailability } from '@redux/reducers/slices/solarEnergyProjectPage';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FormSection } from '@components/forms/MuiFormSection';
import { CheckboxList, InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import InputAdornment from '@mui/material/InputAdornment';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import { Switch } from '@components/controls/react-hook-form-friendly/smart/Switch';
import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { fontFamilyMedium } from '../../../../styles/theme/theme';
import { CustomProductListItem } from '@components/CustomProductListItem/CustomProductListItem';
import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
import { ManageableProductDto, ManageableProductPackageDto, ProductExistenceDto } from '@generatedTypes/data-contracts';
import { EnumberSearch } from '@components/EnumberSearch/EnumberSearch';
import { ProductPackageItemsSection } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/ProductPackageComponents/ProductPackageItemsList';
import { ProductPackageItemsSearch } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/ProductPackageComponents/ProductPackageItemsSearch';
import { AttributeSection } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AttributeSection/AttributeSection';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import {
  useGetSelectedCategory,
  useMapAttributeValuesForEdit,
} from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/utils';
import { useCheckProductByEnumber } from '@services/api/projectProducts/checkProductByEnumber';
import { mdiTrashCanOutline } from '@mdi/js';

export const SELECTABLE_OPTION = `selectable`;

const addEditManageableMaterialOrPackageFormSchema = z
  .object({
    type: z.enum([`material`, `package`]),
    eNumber: z.string().optional(),
    name: z.string().min(1, `Fältet är obligatoriskt`),
    productPackageItems: z.array(z.object({ productId: z.number().int(), quantity: z.number().int() })).optional(),
    warranty: z.coerce.number().int().min(0),
    attributeValues: z.array(
      z.object({
        attributeId: z.number().int(),
        value: z.string().optional(),
        name: z.string().optional(),
      }),
    ),
    enabled: z.boolean(),
    selectable: z.array(z.string()),
  })
  .superRefine((data, ctx) => {
    if (data.type === `material` && (!data.eNumber || isNaN(Number(data.eNumber)))) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Fältet är obligatoriskt`,
        path: [`eNumber`],
      });
    }
    if (data.type === `package` && !data.productPackageItems?.length) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Fältet är obligatoriskt`,
        path: [`productPackageItems`],
      });
    }
  });

export type AddEditManageableMaterialOrPackageValues = z.infer<typeof addEditManageableMaterialOrPackageFormSchema>;

type AddEditManageableMaterialOrPackageFormProps = {
  onClose: () => void;
  onSubmit: (values: AddEditManageableMaterialOrPackageValues) => void;
  errors?: ParsedBackendValidationResults | null;
  disabled?: boolean;
  initialMaterial?: ManageableProductDto;
  initialPackage?: ManageableProductPackageDto;
  onDelete?: () => void;
};

const initialValues: AddEditManageableMaterialOrPackageValues = {
  type: `material`,
  name: ``,
  warranty: 0,
  enabled: false,
  selectable: [],
  productPackageItems: [],
  attributeValues: [],
};

export const AddEditManageableMaterialOrPackageForm: React.FC<AddEditManageableMaterialOrPackageFormProps> = ({
  onClose,
  onSubmit,
  disabled,
  initialMaterial,
  initialPackage,
  onDelete,
}) => {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { addEditForm },
      },
      common: { year },
    },
  } = useTranslations();

  const theme = useTheme();

  const [productExistence, setProductExistence] = useState<ProductExistenceDto | null | undefined>(undefined);
  const selectedCategory = useGetSelectedCategory();
  const mapAttributeValues = useMapAttributeValuesForEdit(selectedCategory);
  const {
    productExistence: localProductExistence,
    checkProductByEnumber,
    isLoadingCheckProductByEnumber,
  } = useCheckProductByEnumber();

  const formFunctions = useForm<AddEditManageableMaterialOrPackageValues>({
    resolver: zodResolver(addEditManageableMaterialOrPackageFormSchema),
    defaultValues: initialValues,
  });
  const { handleSubmit, control, reset } = formFunctions;
  const [productEnabled, name, warranty, type, eNumber, productPackageItems] = useWatch({
    control,
    name: [`enabled`, `name`, `warranty`, `type`, `eNumber`, `productPackageItems`],
  });

  useEffect(() => {
    if (!initialMaterial && !initialPackage) {
      reset({ ...initialValues, type });
      setProductExistence(null);
    }
  }, [initialMaterial, initialPackage, reset, type]);

  useEffect(() => {
    if (initialMaterial?.eNumber) {
      checkProductByEnumber(initialMaterial.eNumber);
    }
  }, [checkProductByEnumber, initialMaterial?.eNumber]);

  useEffect(() => {
    if (initialMaterial && localProductExistence) {
      setProductExistence(localProductExistence);
      const newValues: AddEditManageableMaterialOrPackageValues = {
        ...initialValues,
        type: `material`,
        eNumber: initialMaterial.eNumber,
        name: initialMaterial.name,
        warranty: initialMaterial.warranty ?? 0,
        enabled: initialMaterial.isActive,
        selectable: initialMaterial.canBeExcludedFromBasket ? [SELECTABLE_OPTION] : [],
        attributeValues: mapAttributeValues(initialMaterial.attributeIdToAttributeValueIdMap),
      };
      reset(newValues);
    }
  }, [initialMaterial, localProductExistence, mapAttributeValues, reset]);

  useEffect(() => {
    if (initialPackage) {
      const newValues: AddEditManageableMaterialOrPackageValues = {
        ...initialValues,
        type: `package`,
        name: initialPackage.name,
        warranty: initialPackage.warranty ?? 0,
        enabled: initialPackage.isActive,
        selectable: initialPackage.canBeExcludedFromBasket ? [SELECTABLE_OPTION] : [],
        productPackageItems: initialPackage.packageItems.map((item) => ({
          productId: item.id,
          quantity: item.quantity,
        })),
        attributeValues: mapAttributeValues(initialPackage.attributeIdToAttributeValueIdMap),
      };
      reset(newValues);
    }
  }, [initialPackage, mapAttributeValues, reset]);

  const typeOptions: OptionWithAvailability<AddEditManageableMaterialOrPackageValues[`type`]>[] = [
    { value: `material`, label: translate(addEditForm.singleProduct), disabled: !!initialPackage },
    {
      value: `package`,
      label: translate(addEditForm.package),
      disabled: !selectedCategory?.canAddPackages || !!initialMaterial,
    },
  ];

  const isPackage = type === `package`;
  const canAddMaterialOfEnumber =
    (productExistence?.exists && !productExistence?.productId) || localProductExistence?.exists;
  const showFormElements = isPackage || canAddMaterialOfEnumber;

  return (
    <FormProvider {...formFunctions}>
      <FormWrapper
        onCancel={onClose}
        onSubmit={handleSubmit(onSubmit)}
        title={selectedCategory?.name}
        disabled={disabled}
        titleAction={onDelete}
        iconPath={onDelete ? mdiTrashCanOutline : undefined}
        iconColor={theme.palette.rexelEnergy.ctaBlue}
      >
        <FormSection>
          <ChipList control={control} name="type" chips={typeOptions} />
          {!isPackage && (
            <EnumberSearch
              type="number"
              control={control}
              name="eNumber"
              label={translate(addEditForm.eNumber)}
              isRequired
              onEnumberSearch={setProductExistence}
              showLastDivider={false}
            />
          )}
          {isPackage && <ProductPackageItemsSearch control={control} />}
        </FormSection>
        {isLoadingCheckProductByEnumber && <Skeleton variant="rectangular" height={400} />}
        {showFormElements && (
          <>
            {isPackage && <ProductPackageItemsSection control={control} listItems={productPackageItems} />}
            <FormSection title={translate(addEditForm.materialInformationHeader)}>
              {!isPackage && (
                <Stack>
                  <Typography>{translate(addEditForm.eNumber)}</Typography>
                  <Typography>{eNumber}</Typography>
                </Stack>
              )}
              <InputWrapper control={control} name="name" label={translate(addEditForm.materialName)} isRequired />
              <MuiNumberInput
                control={control}
                name="warranty"
                label={translate(addEditForm.warranty)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{translate(year)}</InputAdornment>,
                }}
              />
              <AttributeSection selectedCategory={selectedCategory} />
            </FormSection>
            <FormSection>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h3">{translate(addEditForm.statusHeader)}</Typography>
                <Switch
                  disabled={false}
                  control={control}
                  name="enabled"
                  label={translate(productEnabled ? addEditForm.statusActive : addEditForm.statusInactive)}
                  labelOnLeft
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontFamily: fontFamilyMedium,
                    },
                  }}
                />
              </Stack>
              <Typography>
                {translate(
                  productEnabled ? addEditForm.statusActiveDescription : addEditForm.statusInactiveDescription,
                )}
              </Typography>
              <CustomProductListItem
                name={name}
                warranty={warranty}
                imageUrl={productExistence?.imageUrl ?? localProductExistence?.imageUrl}
                category={selectedCategory?.name}
                selected={productEnabled}
                interactive={false}
              />
            </FormSection>
            <FormSection title={translate(addEditForm.selectableHeader)}>
              <Stack spacing={2}>
                <Typography>{translate(addEditForm.selectableDescription)}</Typography>
                <CheckboxList
                  control={control}
                  name="selectable"
                  options={[{ label: translate(addEditForm.selectable), value: SELECTABLE_OPTION }]}
                  isLabelOnRight
                />
              </Stack>
            </FormSection>
          </>
        )}
        <DevTool control={control} />
      </FormWrapper>
    </FormProvider>
  );
};
