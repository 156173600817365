import { ManageableProductAttributeRowDto } from '@generatedTypes/data-contracts';
import { Path } from 'react-hook-form';
import { AddEditManageableMaterialOrPackageValues } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageForm';
import { ListInput } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AttributeSection/InputForAttribute/ListInput';
import { NumericInput } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AttributeSection/InputForAttribute/NumericInput';
import { convertToNumber } from '@utils/math';

type InputForAttributeProps = {
  name: Path<AddEditManageableMaterialOrPackageValues>;
  attribute: ManageableProductAttributeRowDto;
};

export const InputForAttribute: React.FC<InputForAttributeProps> = ({ name, attribute }) => {
  const label = `${attribute.name}${attribute.unit ? ` ${attribute.unit}` : ``}`;
  if (!attribute.canAddNewValues) {
    return (
      <ListInput
        name={name}
        label={label}
        options={
          attribute.values.map(({ id, name }) => ({
            attributeId: attribute.id,
            value: String(id),
            name: name ?? ``,
          })) ?? []
        }
      />
    );
  }
  if (attribute.dataType === `Decimal`) {
    return (
      <NumericInput
        name={name}
        label={label}
        options={
          attribute.values.map(({ name }) => ({
            attributeId: attribute.id,
            value: String(convertToNumber(name)) ?? 0,
            name: String(convertToNumber(name)) ?? ``,
          })) ?? []
        }
        decimal
      />
    );
  }
  if (attribute.dataType === `Integer`) {
    return (
      <NumericInput
        name={name}
        label={label}
        options={
          attribute.values.map(({ name }) => ({
            attributeId: attribute.id,
            value: String(convertToNumber(name)) ?? 0,
            name: String(convertToNumber(name)) ?? ``,
          })) ?? []
        }
      />
    );
  }
};
