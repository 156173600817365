import { Button, Stack, Typography } from '@mui/material';
import { ProjectAdditionTemplateDto } from '@generatedTypes/data-contracts';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { CheckboxList } from '@components/controls/react-hook-form-friendly/smart';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { zodResolver } from '@hookform/resolvers/zod';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiPlus } from '@mdi/js';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { getMapAdditionTemplateToCheckboxRow } from './getMapAdditionTemplateToCheckboxRow';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useMemo } from 'react';
import { useGetPartner } from '@services/api/partners';
import { EmptyTemplateList } from '@components/Empty/EmptyTemplateList';

const ProjectAdditionTemplateSelectionZodObject = {
  templateIds: z.array(z.number()),
};

const ProjectAdditionTemplateSelectionZodSchema = z.object(ProjectAdditionTemplateSelectionZodObject);

export type ProjectAdditionTemplateSelectionValuesType = z.infer<typeof ProjectAdditionTemplateSelectionZodSchema>;

type ProjectAdditionTemplateSelectionProps = {
  templates: ProjectAdditionTemplateDto[];
  onClickNewAddition: () => void;
  onSubmitTemplates: (templateIds: number[]) => void;
  onClose: () => void;
};

export const ProjectAdditionTemplateSelection: React.FC<ProjectAdditionTemplateSelectionProps> = ({
  onSubmitTemplates,
  onClickNewAddition,
  templates,
  onClose,
}) => {
  const { partner } = useGetPartner();
  const {
    translate,
    translations: {
      common: { units },
      leads: {
        details: {
          project: {
            additionTemplateSelection: {
              newAdditionButton,
              header,
              chooseTemplateHeader,
              nonDeductibleCosts,
              installationCosts,
              noTemplatesButton,
              noTemplatesDescription,
            },
          },
        },
      },
    },
  } = useTranslations();
  const { control, handleSubmit } = useForm<ProjectAdditionTemplateSelectionValuesType>({
    resolver: zodResolver(ProjectAdditionTemplateSelectionZodSchema),
    defaultValues: { templateIds: [] },
  });

  const handleOnSubmit = (data: ProjectAdditionTemplateSelectionValuesType) => {
    onSubmitTemplates(data.templateIds);
  };

  const mapAdditionsTemplateToCheckboxRow = useMemo(
    () =>
      getMapAdditionTemplateToCheckboxRow({
        pieceTranslation: translate(units.piece),
        hourTranslation: translate(units.hour),
        currencyTranslation: translate(units.currencyCrowns),
        netCostPerHour: partner?.netCostPerHour || 0,
      }),
    [translate, units, partner],
  );

  return (
    <FormWrapper
      onSubmit={handleSubmit(handleOnSubmit)}
      onCancel={onClose}
      title={translate(header)}
      titleVariant="h2"
      titleAction={onClose}
    >
      <Stack spacing={4}>
        <Stack gap={2}>
          <Button
            variant="text"
            startIcon={<MdiIconWrapper path={mdiPlus} />}
            onClick={onClickNewAddition}
            sx={{ justifyContent: `flex-start` }}
          >
            {translate(newAdditionButton)}
          </Button>
          <HorizontalLine />
        </Stack>
        {templates.length ? (
          <>
            <Typography variant="h3">{translate(chooseTemplateHeader)}</Typography>
            <Stack spacing={2}>
              <Typography variant="h4">{translate(installationCosts)}</Typography>
              <CheckboxList
                control={control}
                name="templateIds"
                isLabelOnRight
                options={templates.filter(({ isDeductible }) => isDeductible).map(mapAdditionsTemplateToCheckboxRow)}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h4">{translate(nonDeductibleCosts)}</Typography>
              <CheckboxList
                control={control}
                name="templateIds"
                isLabelOnRight
                options={templates.filter(({ isDeductible }) => !isDeductible).map(mapAdditionsTemplateToCheckboxRow)}
              />
            </Stack>
          </>
        ) : (
          <EmptyTemplateList noTemplatesDescription={noTemplatesDescription} noTemplatesButton={noTemplatesButton} />
        )}
      </Stack>
    </FormWrapper>
  );
};
