import { useCreatePartner } from '@services/api/partners';
import { getPartnerInitialValues, partnerDataZodObject, PartnerForm } from './PartnerForm';
import { useCallback, useEffect, useMemo } from 'react';
import { parseZipCodeInputToPlainValue } from '@utils/parsers';
import { z } from 'zod';
import {
  clientInformationZodObject,
  EditClientInformationForm,
  getDealerDropdownValues,
} from '@pages/partners/partnerDataForms/EditClientInformationForm';
import { useGetDealers } from '@services/api/dealers';
import { getClientInformationInitialValues } from '@pages/partners/partnerDataForms/EditClientInformation';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '@components/forms/MuiFormWrapper';

const editPartnerDataZodSchema = z.object({ ...partnerDataZodObject, ...clientInformationZodObject });

export type NewPartnerDataValues = z.infer<typeof editPartnerDataZodSchema>;

export interface NewPartnerFormProps {
  onClose: () => void;
}

export const NewPartnerForm = ({ onClose }: NewPartnerFormProps) => {
  const { mutate, isLoading, validationErrors } = useCreatePartner();

  const { dealers } = useGetDealers();

  const dealersDropdownValues = useMemo(() => getDealerDropdownValues(dealers), [dealers]);
  const defaultDealerValue = dealersDropdownValues?.[0]?.value;

  const initialValues = useMemo(
    () => ({
      ...getPartnerInitialValues(null),
      ...getClientInformationInitialValues(null, defaultDealerValue),
    }),
    [defaultDealerValue],
  );

  const formFunctions = useForm<NewPartnerDataValues>({
    values: initialValues,
    resolver: zodResolver(editPartnerDataZodSchema),
  });
  const { handleSubmit, reset, setError } = formFunctions;

  const onSubmit = useCallback(
    (values: NewPartnerDataValues) => {
      mutate({
        ...values,
        zipCode: parseZipCodeInputToPlainValue(values.zipCode) ?? ``,
        dealerId: 15,
        accountId: `62017861`,
      });
      onClose();
    },
    [mutate, onClose],
  );

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (validationErrors) {
      Object.entries(validationErrors.errors).forEach(([key, error]) => {
        setError(key as keyof NewPartnerDataValues, { message: error[0] });
      });
    }
  }, [validationErrors, setError]);

  return (
    <FormProvider {...formFunctions}>
      <FormWrapper onCancel={onCancel} onSubmit={handleSubmit(onSubmit)} disabled={isLoading}>
        <PartnerForm disableForm={isLoading} />
        <EditClientInformationForm disableForm={isLoading} dealersDropdownValues={dealersDropdownValues} />
      </FormWrapper>
    </FormProvider>
  );
};
