import { authFetch, getErrorFromFormRequest } from '@services/api/utils';
import { ProductExistenceDto } from '@generatedTypes/data-contracts';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';

export const checkProductByEnumber = async (eNumber: string) => {
  if (!eNumber) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/check-if-exist/${eNumber}`, {
    method: `GET`,
    mode: `cors`,
  });
  if (response.ok) {
    return (await response.json()) as ProductExistenceDto;
  }
  throw await getErrorFromFormRequest(response);
};
export const useCheckProductByEnumber = () => {
  const { data, isLoading, mutate, mutateAsync } = useCustomMutation({
    mutationFn: checkProductByEnumber,
  });

  return {
    productExistence: data ?? null,
    isLoadingCheckProductByEnumber: isLoading,
    checkProductByEnumber: mutate,
    asyncCheckProductByEnumber: mutateAsync,
  };
};
