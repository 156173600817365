import { authFetch } from '@services/api/utils';
import { ManageableProductCategoryRowDto } from '@generatedTypes/data-contracts';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { PRODUCT_MANAGEMENT_PRODUCT_CATEGORIES } from '@variables/queryKeys';

export const fetchManageableProductCategories = async () => {
  const url = `${process.env.PROTECTED_API_URL}/product-management/categories`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ManageableProductCategoryRowDto[];
  }
  throw new Error(json.message);
};

export const useGetManageableProductCategories = () => {
  const { data, isLoading } = useCustomQuery({
    queryKey: PRODUCT_MANAGEMENT_PRODUCT_CATEGORIES,
    queryFn: fetchManageableProductCategories,
  });

  return {
    manageableProductCategories: data || [],
    isLoadingManageableProductCategories: isLoading,
  };
};
