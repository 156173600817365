import {
  AddEditManageableMaterialOrPackageValues,
  SELECTABLE_OPTION,
} from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageForm';
import { Common } from '@utils/types/common';
import {
  CreateProductPackageRequest,
  CreateProductRequest,
  ManageableProductCategoryRowDto,
  ProductAttributeDataType,
  ProductAttributeRequest,
} from '@generatedTypes/data-contracts';
import { useSelector } from 'react-redux';
import { selectSelectedManageableProductCategoryId } from '@redux/reducers/slices/productManagement';
import { useGetManageableProductCategories } from '@services/api/productManagement/categories';
import { useCallback, useMemo } from 'react';

export const useGetSelectedCategory = () => {
  const { manageableProductCategories } = useGetManageableProductCategories();
  const selectedManageableProductCategoryId = useSelector(selectSelectedManageableProductCategoryId);
  return useMemo(
    () => manageableProductCategories.find((category) => category.id === selectedManageableProductCategoryId),
    [manageableProductCategories, selectedManageableProductCategoryId],
  );
};

export const useMapAttributeValues = (): ((
  attributeValues: AddEditManageableMaterialOrPackageValues[`attributeValues`],
) => ProductAttributeRequest[]) => {
  const selectedCategory = useGetSelectedCategory();
  return (attributeValues: AddEditManageableMaterialOrPackageValues[`attributeValues`]) =>
    attributeValues.map((attributeValue) =>
      selectedCategory?.attributes.find(({ id }) => id === attributeValue.attributeId)?.dataType ===
      ProductAttributeDataType.Alphanumeric
        ? {
            attributeId: attributeValue.attributeId,
            attributeValueId: Number(attributeValue.value),
          }
        : {
            attributeId: attributeValue.attributeId,
            value: attributeValue.value,
          },
    );
};

export const getCommonFieldsFromFormValues = (
  values: AddEditManageableMaterialOrPackageValues,
  mapAttributeValues: ReturnType<typeof useMapAttributeValues>,
): Omit<Common<CreateProductRequest, CreateProductPackageRequest>, `categoryId`> | null => ({
  name: values.name,
  warranty: values.warranty,
  isActive: values.enabled,
  canBeExcludedFromBasket: values.selectable.includes(SELECTABLE_OPTION),
  attributeValues: mapAttributeValues(values.attributeValues),
});

export const getProductFieldsFromFormValues = (
  values: AddEditManageableMaterialOrPackageValues,
  mapAttributeValues: ReturnType<typeof useMapAttributeValues>,
): Omit<CreateProductRequest, `categoryId`> | null => {
  const commonFields = getCommonFieldsFromFormValues(values, mapAttributeValues);

  if (!commonFields || !values.eNumber) {
    return null;
  }

  return { ...commonFields, eNumber: String(values.eNumber) };
};

export const getProductPackageFieldsFromFormValues = (
  values: AddEditManageableMaterialOrPackageValues,
  mapAttributeValues: ReturnType<typeof useMapAttributeValues>,
): Omit<CreateProductPackageRequest, `categoryId`> | null => {
  const commonFields = getCommonFieldsFromFormValues(values, mapAttributeValues);

  if (!commonFields || !values.productPackageItems?.length) {
    return null;
  }

  return {
    ...commonFields,
    productPackageItems: values.productPackageItems?.map(({ productId, quantity }) => ({
      productId,
      quantity,
    })),
  };
};

export const useMapAttributeValuesForEdit = (selectedCategory: ManageableProductCategoryRowDto | undefined) =>
  useCallback(
    (attributeValuesMap: Record<string, number>) =>
      selectedCategory?.attributes.map((attribute) => {
        const attributeValueId = attributeValuesMap[Number(attribute.id)] ?? 0;
        const attributeValueName = attribute?.values?.find((v) => v.id === attributeValueId)?.name ?? undefined;
        const value = attribute?.canAddNewValues ? attributeValueName : String(attributeValueId);
        return {
          attributeId: Number(attribute.id),
          value,
          name: attributeValueName,
        };
      }) ?? [],
    [selectedCategory],
  );
