export const TO_KILO_SI = 1000;

export function sumHandler(numbers: number[], fixedLength = 2) {
  if (!isArrayNumeric(numbers)) return;
  if (!numbers) return;

  const result = numbers.reduce((acc: number, curentValue: number): number => acc + curentValue, 0) as number;

  return parseFloat(result.toFixed(fixedLength));
}

export function isNumber(value: number | unknown) {
  return typeof value === `number` && !isNaN(value);
}

export function isArrayNumeric(arr: number[]) {
  if (!arr) return;
  return arr.every((val) => isNumber(val));
}

export function convertToNumber(toConvert: string | number | null | undefined): number {
  if (!toConvert) return 0;

  toConvert = toConvert + ``;
  const modStr = toConvert.replace(`,`, `.`);
  return parseFloat(modStr);
}

export function convertToStringAsNumber(toConvert: string | number | null | undefined, allowEmpty = false): string {
  if (!toConvert) return allowEmpty ? `` : `0`;

  const str = toConvert + ``;
  return str.replace(`.`, `,`);
}

/**
 * @description Clamp a value between a min and max value
 * @param value The value to clamp
 * @param min The minimum allowed value
 * @param max The maximum allowed value
 */
export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}
