import { Box, IconButton, useTheme } from '@mui/material';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import { getFormattedPriceWithStaticFraction, parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import { useToggle } from '@hooks/useToggle';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { RoundingToggleButton } from '@pages/NewLeads/offer/price/RoundingToggleButton';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiReplay } from '@mdi/js/commonjs/mdi';
import { mdiCheck } from '@mdi/js';

type RoundingEditRowProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  formDisabled?: boolean;
  offerLocked?: boolean;
  onSubmit?: () => void;
  onReset?: () => void;
  initialNetPriceRounding?: number;
};
export const RoundingEditRow = <T extends FieldValues>({
  formDisabled = false,
  offerLocked = false,
  control,
  fieldName,
  initialNetPriceRounding,
  onSubmit,
  onReset,
}: RoundingEditRowProps<T>) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { priceOverviewPage },
      },
    },
  } = useTranslations();
  const [roundingEditOpened, toggleRoundingEditOpened] = useToggle();
  const { field } = useController({ control, name: fieldName });
  const theme = useTheme();

  return roundingEditOpened ? (
    <Box sx={{ display: `flex`, justifyContent: `space-between`, alignItems: `center`, margin: `-0.75rem 0` }}>
      <RoundingToggleButton disabled>{translate(priceOverviewPage.rounding)}</RoundingToggleButton>
      <Box sx={{ display: `flex`, alignItems: `center` }}>
        {onReset && (
          <IconButton
            size="small"
            onClick={() => {
              onReset();
              toggleRoundingEditOpened();
            }}
          >
            <MdiIconWrapper path={mdiReplay} iconColor={theme.palette.text.primary} />
          </IconButton>
        )}
        <IconButton
          size="small"
          onClick={() => {
            onSubmit?.();
            toggleRoundingEditOpened();
          }}
        >
          <MdiIconWrapper path={mdiCheck} iconColor={theme.palette.text.primary} />
        </IconButton>
        <MuiNumberInput
          control={control}
          name={fieldName}
          inputProps={{ style: { textAlign: `right`, maxWidth: `4rem` } }}
        />
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: `flex`, justifyContent: `space-between`, alignItems: `center` }}>
      <RoundingToggleButton
        onClick={!offerLocked ? toggleRoundingEditOpened : undefined}
        disabled={formDisabled || offerLocked}
      >
        {translate(priceOverviewPage.rounding)}
      </RoundingToggleButton>
      <p>
        {getFormattedPriceWithStaticFraction(
          initialNetPriceRounding ?? parseInputValueToSubmitNumberValue(field.value),
          2,
        )}
      </p>
    </Box>
  );
};
