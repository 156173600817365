import { ProductAttributeFilterType } from '@generatedTypes/data-contracts';
import React from 'react';
import { Slider } from '@components/controls/react-hook-form-friendly/dumb/Slider';
import { Control, useController, useFormContext } from 'react-hook-form';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { convertFormValuesToAppliedFilters } from './utils/utils';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Checkbox } from '@components/controls/react-hook-form-friendly/dumb/Checkbox/Checkbox';
import {
  ProductAttributeRowListProps,
  ProductAttributeRowNumberProps,
  ProductsAttributeFiltersSchemaType,
} from './utils/types';

type ProductAttributeRowProps<AttributeType extends ProductsAttributeFiltersSchemaType[number]> = {
  control: Control<ProductsAttributeFiltersSchemaType>;
  index: number;
  attribute: AttributeType;
};

const ProductAttributeListControl: React.FC<ProductAttributeRowProps<ProductAttributeRowListProps>> = ({
  control,
  index,
  attribute,
}) => (
  <ChipList
    key={String(attribute.attributeId)}
    control={control}
    name={`${index}.value`}
    chips={attribute.options ?? []}
    version={`small`}
    wrapChips
  />
);

const ProductAttributeNumberControl: React.FC<ProductAttributeRowProps<ProductAttributeRowNumberProps>> = ({
  control,
  attribute,
  index,
}) => {
  const { field: valueField } = useController({ control, name: `${index}.value` });
  return <Slider min={0} max={attribute.max ?? 0} value={valueField.value} onChange={valueField.onChange} />;
};

const ProductAttributeRow: React.FC<ProductAttributeRowProps<ProductsAttributeFiltersSchemaType[number]>> = ({
  attribute,
  index,
  control,
}) => {
  const { field: activeField } = useController({ control, name: `${index}.active` });
  let AttributeControl = null;
  switch (attribute.type) {
    case ProductAttributeFilterType.List:
      AttributeControl = <ProductAttributeListControl control={control} attribute={attribute} index={index} />;
      break;
    case ProductAttributeFilterType.Number:
      AttributeControl = <ProductAttributeNumberControl control={control} attribute={attribute} index={index} />;
      break;
    default:
      return null;
  }
  return (
    <Stack spacing={3}>
      <Checkbox
        checked={activeField.value ?? false}
        onChange={activeField.onChange}
        value="active"
        name="active"
        label={attribute.attributeName}
      />
      {attribute.active && AttributeControl}
      <Divider />
    </Stack>
  );
};

export const ProductAttributeRows: React.FC = () => {
  const formFunctions = useFormContext<ProductsAttributeFiltersSchemaType>();
  const fields = convertFormValuesToAppliedFilters(formFunctions?.getValues()) ?? [];

  return (
    <>
      {fields.map((attribute, index) => (
        <ProductAttributeRow
          key={String(attribute.attributeId)}
          attribute={attribute}
          index={index}
          control={formFunctions.control}
        />
      ))}
    </>
  );
};
