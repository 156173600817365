import { Card } from '@components/Card/Card';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { EditClientInformation } from './partnerDataForms/EditClientInformation';
import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from '@redux/reducers/slices/user';

export interface ClientInformationCardProps {
  partner: PartnerDto;
}

export const ClientInformationCard = ({ partner }: ClientInformationCardProps) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();
  const [showEditClientInformation, toggleShowEditClientInformation] = useToggle();
  const isPartnerUser = useSelector(selectIsPartnerUser);

  return (
    <Card
      title={translate(details.clientInformation.header)}
      titleAction={!isPartnerUser ? toggleShowEditClientInformation : undefined}
      titleActionIcon={!isPartnerUser ? mdiPencil : undefined}
      titleActionTestId="edit-client-information"
    >
      <Stack spacing={3}>
        <Stack>
          <Typography variant="body2">{translate(details.clientInformation.clientNumberHeader)}</Typography>
          <Typography>{partner?.accountId}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2">{translate(details.clientInformation.associatedDealerHeader)}</Typography>
          <div>{partner?.dealer ? partner.dealer.name : translate(details.clientInformation.dealerNotSetUp)}</div>
        </Stack>
      </Stack>
      <Drawer anchor="right" open={showEditClientInformation} onClose={toggleShowEditClientInformation}>
        <EditClientInformation onClose={toggleShowEditClientInformation} partner={partner} />
      </Drawer>
    </Card>
  );
};
