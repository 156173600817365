import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, useTheme } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiExportVariant } from '@mdi/js';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';

type ExportDropdownProps = {
  exportButtonText: string;
  exportButtonAction: () => void;
};

export const ExportDropdown: React.FC<ExportDropdownProps> = ({ exportButtonText, exportButtonAction }) => {
  const [isDropdownOpen, toggleIsDropdownOpen] = useToggle();
  const theme = useTheme();

  return (
    <Dropdown
      isDropdownOpen={isDropdownOpen}
      toggleIsDropdownOpen={toggleIsDropdownOpen}
      placeIconAfterText
      styles={{
        padding: 1,
        minWidth: 0,
        '& svg': {
          color: theme.palette.text.primary,
        },
      }}
    >
      <MenuItem
        onClick={() => {
          exportButtonAction();
          toggleIsDropdownOpen();
        }}
      >
        <ListItemIcon>
          <MdiIconWrapper path={mdiExportVariant} iconColor="black" />
        </ListItemIcon>
        {exportButtonText}
      </MenuItem>
    </Dropdown>
  );
};
