import { DeductionScope, DeductionTemplateDto, DeductionType } from '@generatedTypes/data-contracts';
import { ColumnItem } from '@components/columnLayout/utils';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { z } from 'zod';
import { requiredString } from '@variables/zod';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputSuffixWrapperSmart, InputWrapper, Radio } from '@components/controls/react-hook-form-friendly/smart';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { useCallback, useEffect } from 'react';
import { DevTool } from '@hookform/devtools';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { Stack, Typography } from '@mui/material';
import { getPercentageValues, getSumAndPercentageValues, getSumValues } from './utils';
import { useGetOffer } from '@services/api/offers/offers';
import { useSelector } from 'react-redux';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { getCalculatedFormattedGrossPrice } from '@pages/NewLeads/utils';
import { DeleteDialog } from '@components/deleteItem/DeleteButton';

type AddEditDeductionProps = {
  closeModal: () => void;
  onDelete: (projectAdditionId: number) => void;
  onSubmitDeductionTemplate: (formData: DeductionValuesType) => void;
  beValidationResults?: ParsedBackendValidationResults | null;
  disableForm?: boolean;
  initialDeductionTemplate?: Omit<DeductionTemplateDto, `partnerId`>;
};

const deductionZodObject = {
  name: requiredString(),
  value: z.number(),
  type: z.nativeEnum(DeductionType),
  scope: z.nativeEnum(DeductionScope),
  percentValue: z.string().optional(),
  sumValue: z.string().optional(),
};

const deductionZodSchema = z.object(deductionZodObject);

export type DeductionValuesType = z.infer<typeof deductionZodSchema>;

export const AddEditDeductionForm: ColumnItem<AddEditDeductionProps> = ({
  closeModal,
  onDelete,
  onSubmitDeductionTemplate,
  disableForm,
  initialDeductionTemplate,
}) => {
  const {
    translate,
    translations: {
      editProduct: { deductionTemplate },
    },
  } = useTranslations();
  const { offer } = useGetOffer();
  const currentProjectId = useSelector(selectCurrentProjectId);
  const bidPrices = offer?.price.bidPrices.find((b) => b.projectId === currentProjectId);

  const { sum, percentage } = getSumAndPercentageValues(
    String(initialDeductionTemplate?.value ?? 0),
    initialDeductionTemplate?.type ?? DeductionType.Percentage,
    bidPrices?.totalNetPrice ?? 0,
  );
  const initialValues: DeductionValuesType = {
    name: initialDeductionTemplate?.name ?? ``,
    scope: initialDeductionTemplate?.scope ?? DeductionScope.MaterialsAndInstallation,
    value: initialDeductionTemplate?.value ?? 0,
    type: initialDeductionTemplate?.type ?? DeductionType.Percentage,
    percentValue: percentage,
    sumValue: sum,
  };

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    resolver: zodResolver(deductionZodSchema),
    values: initialValues,
    defaultValues: initialValues,
  });

  const handleDelete = useCallback(() => {
    if (!initialDeductionTemplate?.id) {
      return;
    }
    onDelete(initialDeductionTemplate.id);
  }, [onDelete, initialDeductionTemplate?.id]);

  const [selectedType, sumValue, percentValue, scope] = watch([`type`, `sumValue`, `percentValue`, `scope`]);

  useEffect(() => {
    if (selectedType === DeductionType.Sum) {
      if (bidPrices?.totalNetPriceWithoutDeduction) {
        const valueToBaseOn =
          scope === DeductionScope.TotalProjectCost
            ? bidPrices?.totalNetPriceWithoutDeduction
            : bidPrices?.totalMaterialAndInstallationNetPrice;
        setValue(`percentValue`, getPercentageValues(sumValue ?? `0`, valueToBaseOn));
      } else {
        setValue(`percentValue`, `0`);
      }
      setValue(`value`, isNaN(Number(sumValue)) ? 0 : Number(sumValue));
    }
  }, [
    bidPrices?.totalMaterialAndInstallationNetPrice,
    bidPrices?.totalNetPriceWithoutDeduction,
    scope,
    selectedType,
    setValue,
    sumValue,
  ]);

  useEffect(() => {
    if (selectedType === DeductionType.Percentage) {
      if (bidPrices?.totalNetPriceWithoutDeduction) {
        const valueToBaseOn =
          scope === DeductionScope.TotalProjectCost
            ? bidPrices?.totalNetPriceWithoutDeduction
            : bidPrices?.totalMaterialAndInstallationNetPrice;
        setValue(`sumValue`, getSumValues(percentValue ?? `0`, valueToBaseOn));
      } else {
        setValue(`sumValue`, `0`);
      }
      setValue(`value`, isNaN(Number(percentValue)) ? 0 : Number(percentValue));
    }
  }, [
    selectedType,
    setValue,
    percentValue,
    bidPrices?.totalNetPriceWithoutDeduction,
    bidPrices?.totalMaterialAndInstallationNetPrice,
    scope,
  ]);

  const calculatedSumValue = useWatch({ control, name: `sumValue` });

  return (
    <FormWrapper
      onCancel={() => {
        closeModal();
        reset();
      }}
      onSubmit={handleSubmit((formData) => {
        onSubmitDeductionTemplate(formData);
        reset();
      })}
      disabled={disableForm}
    >
      <Stack gap={2}>
        <div className="row space-between align-center">
          <Typography variant="h2">{translate(deductionTemplate.header)}</Typography>
          {!disableForm && initialDeductionTemplate?.id ? (
            <DeleteDialog
              onDelete={handleDelete}
              confirmationPopupContent={translate(deductionTemplate.removeText)}
              confirmationPopupTitle={translate(deductionTemplate.removeTitle)}
            />
          ) : null}
        </div>
        <InputWrapper label={translate(deductionTemplate.name)} control={control} name="name" isRequired />
        <HorizontalLine />
        <Typography variant="h3">{translate(deductionTemplate.scopeHeader)}</Typography>
        <Radio
          control={control}
          name="scope"
          value={DeductionScope.MaterialsAndInstallation}
          label={translate(deductionTemplate.materialAndInstallation)}
          classesForContainer="row align-center reversed-direction to-the-end"
        />
        <Radio
          control={control}
          name="scope"
          value={DeductionScope.TotalProjectCost}
          label={translate(deductionTemplate.totalCost)}
          classesForContainer="row align-center reversed-direction to-the-end"
        />
        <HorizontalLine />
        <Typography variant="h3">{translate(deductionTemplate.typeHeader)}</Typography>
        <Radio
          control={control}
          name="type"
          value={DeductionType.Percentage}
          label={translate(deductionTemplate.percent)}
          classesForContainer="row align-center reversed-direction to-the-end"
        />
        <InputSuffixWrapperSmart
          name="percentValue"
          control={control}
          type="number"
          suffix="%"
          isDisabled={selectedType !== DeductionType.Percentage}
        />
        <Radio
          control={control}
          name="type"
          value={DeductionType.Sum}
          label={translate(deductionTemplate.sum)}
          classesForContainer="row align-center reversed-direction to-the-end"
        />
        <InputSuffixWrapperSmart
          name="sumValue"
          control={control}
          type="number"
          suffix="kr"
          isDisabled={selectedType !== DeductionType.Sum}
        />
        <Typography variant="subtitle2" ml={2}>
          {`${translate(deductionTemplate.inclTaxPrefix)} ${getCalculatedFormattedGrossPrice(Number(calculatedSumValue))}`}
        </Typography>
        <HorizontalLine />
        <DevTool control={control} />
      </Stack>
    </FormWrapper>
  );
};
