import { useFormContext } from 'react-hook-form';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { ColumnItem } from '@components/columnLayout/utils';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { requiredEmail, requiredNumericString, requiredString } from '@variables/zod';
import { z } from 'zod';
import { FormSection } from '@components/forms/MuiFormSection';

export const getPartnerInitialValues = (partner: PartnerDto | null) => ({
  name: partner?.name ?? ``,
  organizationNumber: partner?.organizationNumber ?? ``,
  contactPerson: partner?.contactPerson ?? ``,
  email: partner?.email ?? ``,
  phoneNumber: partner?.phoneNumber ?? ``,
  street: partner?.address.street ?? ``,
  zipCode: partner?.address.zipCode ?? ``,
  city: partner?.address.city ?? ``,
});

export const partnerDataZodObject = {
  name: requiredString(),
  organizationNumber: requiredString(),
  contactPerson: requiredString(),
  email: requiredEmail,
  phoneNumber: requiredNumericString(),
  street: requiredString(),
  zipCode: requiredString(),
  city: requiredString(),
};

const partnerDataZodSchema = z.object(partnerDataZodObject);

type PartnerDataValues = z.infer<typeof partnerDataZodSchema>;

type PartnerFormProps = {
  disableForm: boolean;
};

export const PartnerForm: ColumnItem<PartnerFormProps> = ({ disableForm }) => {
  const {
    translate,
    translations: {
      partners: { create },
    },
  } = useTranslations();

  const { control } = useFormContext<PartnerDataValues>();

  return (
    <>
      <FormSection title={translate(create.header)}>
        <InputWrapper
          control={control}
          name="name"
          label={translate(create.companyName)}
          type="text"
          isDisabled={disableForm}
          isRequired
        />
        <InputWrapper
          control={control}
          name="organizationNumber"
          label={translate(create.organizationNumber)}
          mask="999999-9999"
          type="text"
          isDisabled={disableForm}
          isRequired
        />
      </FormSection>
      <FormSection title={translate(create.addressHeader)}>
        <InputWrapper
          control={control}
          name="street"
          label={translate(create.street)}
          isDisabled={disableForm}
          type="text"
          isRequired
        />
        <div className="gap-extra-small grid-container">
          <div className="col-6">
            <InputWrapper
              control={control}
              name="zipCode"
              label={translate(create.postCode)}
              mask="999 99"
              type="text"
              isDisabled={disableForm}
              isRequired
            />
          </div>
          <div className="col-6">
            <InputWrapper
              control={control}
              name="city"
              label={translate(create.city)}
              isDisabled={disableForm}
              type="text"
              isRequired
            />
          </div>
        </div>
      </FormSection>
      <FormSection title={translate(create.contactHeader)}>
        <InputWrapper
          control={control}
          name="contactPerson"
          label={translate(create.contactPerson)}
          type="text"
          isDisabled={disableForm}
          isRequired
        />
        <InputWrapper
          control={control}
          name="email"
          label={translate(create.email)}
          type="email"
          isDisabled={disableForm}
          isRequired
        />
        <InputWrapper
          control={control}
          name="phoneNumber"
          label={translate(create.phone)}
          type="tel"
          isDisabled={disableForm}
          isRequired
        />
      </FormSection>
    </>
  );
};
