import { AddEditManageableMaterialOrPackageValues } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageForm';
import { Controller, Path, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { convertToNumber, convertToStringAsNumber } from '@utils/math';
import TextFieldCore from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { useState } from 'react';

const inputChangeRegexForDecimal = /^[0-9]+([.,][0-9]{0,2})?$/;
const inputBlurRegexForDecimal = /^[0-9]+([.,][0-9]{1,2})?$/;
const inputRegexForInteger = /^[0-9]+$/;

type DecimalInputProps = {
  label: string;
  name: Path<AddEditManageableMaterialOrPackageValues>;
  options: { attributeId: number; value: string; name: string }[];
  decimal?: boolean;
};
export const NumericInput: React.FC<DecimalInputProps> = ({ name, label, options, decimal }) => {
  const { control, setValue } = useFormContext<AddEditManageableMaterialOrPackageValues>();
  const [inputValue, setInputValue] = useState<string>(``);

  const handleChange = (value: string) => {
    setInputValue(value);
    setValue(name, {
      attributeId: options[0]?.attributeId,
      value: String(convertToNumber(inputValue)) ?? undefined,
      name: inputValue ?? undefined,
    });
  };

  const getParsedInputChange = (value: string) => {
    const regex = decimal ? inputChangeRegexForDecimal : inputRegexForInteger;
    const inputValid = regex.test(value) || !value;
    return inputValid ? value : inputValue;
  };

  const getParsedInputBlur = () => {
    const regex = decimal ? inputBlurRegexForDecimal : inputRegexForInteger;
    const inputValid = regex.test(inputValue) || !inputValue;
    return inputValid ? inputValue : inputValue.replace(/[^0-9]/g, ``);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, ref, value }, fieldState: { error } }) => (
        <Autocomplete
          onBlur={() => {
            const numberValue = getParsedInputBlur();
            handleChange(numberValue);
            onBlur();
          }}
          value={
            inputValue || (value as AddEditManageableMaterialOrPackageValues[`attributeValues`][number])?.name || ``
          }
          onChange={(_, v) => {
            handleChange(typeof v === `number` || typeof v === `string` ? v : v.name);
          }}
          inputValue={
            inputValue || (value as AddEditManageableMaterialOrPackageValues[`attributeValues`][number])?.name || ``
          }
          onInputChange={(_, v) => {
            const testedValue = getParsedInputChange(v);
            const parsedValue = convertToStringAsNumber(testedValue, true);

            handleChange(parsedValue);
          }}
          disableClearable
          selectOnFocus
          freeSolo
          autoSelect
          id={name}
          options={options || []}
          isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
          getOptionLabel={(option) =>
            (option as AddEditManageableMaterialOrPackageValues[`attributeValues`][number])?.name ??
            (option as string) ??
            ``
          }
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              <span>{convertToStringAsNumber(option.name)}</span>
            </li>
          )}
          renderInput={(params) => (
            <NumericFormat
              {...params}
              label={label}
              required
              getInputRef={ref}
              customInput={TextFieldCore}
              error={!!error}
              decimalSeparator=","
              decimalScale={decimal ? 2 : 0}
              placeholder="0"
            />
          )}
        />
      )}
    />
  );
};
