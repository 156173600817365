import { FormControlLabel, Switch } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export type SwitchDumbProps = {
  checked: boolean;
  label?: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLLabelElement>;
  labelOnLeft?: boolean;
  sx?: SystemStyleObject;
};

export const SwitchDumb: React.FC<SwitchDumbProps> = ({
  checked,
  label,
  disabled = false,
  onClick,
  labelOnLeft = false,
  sx = {},
}) => {
  return (
    <FormControlLabel
      sx={(theme) => ({ display: `inline-flex`, gap: theme.spacing(1), margin: 0, padding: 0, ...sx })}
      control={<Switch />}
      label={label}
      disabled={disabled}
      checked={checked}
      onClick={onClick}
      labelPlacement={labelOnLeft ? `start` : `end`}
    />
  );
};
