export const parseZipCodeInputToPlainValue = (value: string | null | undefined): string | undefined =>
  value?.replace(/[^.*\d]/, ``);

export const parsePhoneNumber = (phoneNumber?: string | null): string | undefined => {
  if (!phoneNumber) {
    return;
  }
  if (phoneNumber.length === 9) {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{3})/, `$1 $2 $3`);
  }
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, `$1 $2 $3 $4`);
};
