import { PartnerDto } from '@generatedTypes/data-contracts';
import { PutPartner } from '@services/api/partners/partners';

export const parsePartnerForUpdate = (partner: PartnerDto): PutPartner => ({
  name: partner.name,
  organizationNumber: partner.organizationNumber,
  contactPerson: partner.contactPerson,
  email: partner.email,
  phoneNumber: partner.phoneNumber,
  street: partner.address?.street ?? ``,
  zipCode: partner.address?.zipCode ?? ``,
  city: partner.address?.city ?? ``,
  id: partner.id ?? -1,
  termsAndConditions: partner.termsAndConditions,
  offerIntroTextTemplate: partner.offerIntroTextTemplate,
  carChargingProjectOfferTextTemplate: partner.carChargingProjectOfferTextTemplate,
  solarEnergyProjectOfferTextTemplate: partner.solarEnergyProjectOfferTextTemplate,
  emptyProjectOfferTextTemplate: partner.emptyProjectOfferTextTemplate,
  netCostPerHour: partner.netCostPerHour,
  defaultProjectMargin: partner.defaultProjectMargin,
});
