import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useGetManageableProductCategories } from '@services/api/productManagement/categories';
import { defaultWidthForm } from '@components/forms/forms.styled';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiClose } from '@mdi/js';
import { ManageableProductCategoryRowDto } from '@generatedTypes/data-contracts';
import List from '@mui/material/List';
import { ListItem } from '@components/ListMui/ListItem';

type ManageableProductCategoryListProps = {
  onClose: () => void;
  onSelectCategory: (categoryId: ManageableProductCategoryRowDto[`id`]) => void;
};

export const ManageableProductCategoryList: React.FC<ManageableProductCategoryListProps> = ({
  onClose,
  onSelectCategory,
}) => {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { categorySelection },
      },
    },
  } = useTranslations();

  const { manageableProductCategories, isLoadingManageableProductCategories } = useGetManageableProductCategories();

  return (
    <Box sx={{ p: 3, width: defaultWidthForm }}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{translate(categorySelection.header)}</Typography>
          <IconButton onClick={onClose}>
            <MdiIconWrapper path={mdiClose} />
          </IconButton>
        </Stack>
        <List sx={{ marginInline: -3 }}>
          {!isLoadingManageableProductCategories &&
            manageableProductCategories.map((category) => (
              <ListItem
                title={category.name}
                onClick={() => onSelectCategory(category.id)}
                key={category.id}
                sx={{ paddingInline: 3 }}
              />
            ))}
        </List>
      </Stack>
    </Box>
  );
};
