import './offerPriceOverviewPage.css';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React, { useCallback, useEffect, useState } from 'react';
import { GetOfferBidPriceOverview } from '@generatedTypes/data-contracts';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';
import { getFormattedPriceWithStaticFraction, parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import {
  getCalculatedPrices,
  getNewPricesAfterCalculation,
  parsePriceOverviewValuesForAPI,
  updateOfferPriceOverviewFormWithCalculation,
} from '@pages/NewLeads/offer/price/utils';
import { PriceOverviewProjectRow } from '@pages/NewLeads/offer/price/PriceOverviewProjectRow';
import {
  useEditOfferPriceOverview,
  useGetOfferPriceCalculationWithDeductions,
  useGetOfferPriceOverview,
} from '@services/api/offers/priceOverview';
import { SlideInViewFormWrapper } from '@components/slideInView/SlideInViewFormWrapper';
import { useGetOffer } from '@services/api/offers/offers';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { RoundingEditRow } from '@pages/NewLeads/offer/price/RoundingEditRow';
import Divider from '@mui/material/Divider';
import { useRunDebouncedFunctionWhenValuesAreRefreshed } from '@hooks/useRunDebouncedFunctionWhenValuesAreRefreshed';

const OfferPriceOverviewZodObject = z.object({
  totalNetPrice: z.coerce.number(),
  totalGrossPriceRounding: z.coerce.number(),
  shouldResetRounding: z.boolean(),
  bids: z.array(
    z.object({
      id: z.number(),
      margin: z.coerce.number(),
      calculatedMargin: z.number(),
      netPrice: z.number(),
      totalNetPriceWithoutDeduction: z.number(),
    }),
  ),
});

export type OfferPriceOverviewSchemaType = z.infer<typeof OfferPriceOverviewZodObject>;

export type OfferPriceDetailsPageProps = {
  onClose: () => void;
  isDisabled: boolean;
};

const initialValues = {
  totalGrossPriceRounding: 0,
  totalNetPrice: 0,
  bids: [],
  shouldResetRounding: false,
};

export const OfferPriceOverviewPage: React.FC<OfferPriceDetailsPageProps> = ({ onClose, isDisabled }) => {
  const pushDataLayer = useAnalytics();
  const {
    translate,
    translations: {
      leads: {
        offer: { priceOverviewPage },
      },
    },
  } = useTranslations();
  const { editOfferPriceOverviewAsync } = useEditOfferPriceOverview();
  const { offerPriceCalculationWithDeductions, getOfferPriceOverviewSimulationData } =
    useGetOfferPriceCalculationWithDeductions();

  const { leadProjects } = useGetLeadProjects();
  const { offerPriceOverview } = useGetOfferPriceOverview();
  const { offer } = useGetOffer();

  const defaultValues: OfferPriceOverviewSchemaType =
    getNewPricesAfterCalculation(offerPriceOverview, offerPriceCalculationWithDeductions, null) ?? initialValues;

  const offerPriceOverViewToShow = offerPriceCalculationWithDeductions ?? offerPriceOverview;

  const formFunctions = useForm<OfferPriceOverviewSchemaType>({
    defaultValues,
    resolver: zodResolver(OfferPriceOverviewZodObject),
  });
  const { control, getValues, setValue, watch, formState } = formFunctions;

  const [priceRounding, shouldResetPriceRounding] = watch([`totalGrossPriceRounding`, `shouldResetRounding`]);

  const [sumValues, setSumValues] = useState<ReturnType<typeof getCalculatedPrices>>({
    calculatedVatPrice: 0,
    netPriceWithVat: 0,
    totalNetPrice: 0,
  });

  useEffect(() => {
    if (shouldResetPriceRounding && priceRounding !== offerPriceOverview?.defaultTotalGrossPriceRounding) {
      setValue(`shouldResetRounding`, false);
    }
  }, [setValue, priceRounding, offerPriceOverview?.defaultTotalGrossPriceRounding, shouldResetPriceRounding]);

  useEffect(() => {
    const currentValues = getValues();
    updateOfferPriceOverviewFormWithCalculation(
      getNewPricesAfterCalculation(offerPriceOverview, offerPriceCalculationWithDeductions, currentValues),
      setValue,
      formState.dirtyFields.totalGrossPriceRounding,
    );
  }, [
    formState.dirtyFields.totalGrossPriceRounding,
    getValues,
    offerPriceCalculationWithDeductions,
    offerPriceOverview,
    setValue,
  ]);

  watch((data) => {
    setSumValues(getCalculatedPrices(data?.totalNetPrice, data.totalGrossPriceRounding));
  });

  const getSimulation = useCallback(() => {
    pushDataLayer({
      event: AnalyticEvents.priceSimulation,
      projectType: leadProjects?.map(({ type }) => type).join(`, `),
    });
    if (offer?.id) {
      const bids: GetOfferBidPriceOverview[] = getValues(`bids`).map((bid) => {
        return {
          id: bid.id,
          margin: parseInputValueToSubmitNumberValue(bid.margin) ?? null,
        };
      });
      getOfferPriceOverviewSimulationData({
        offerId: offer.id,
        bids,
      });
    }
  }, [getOfferPriceOverviewSimulationData, getValues, leadProjects, offer?.id, pushDataLayer]);

  const debouncedSubmit = useRunDebouncedFunctionWhenValuesAreRefreshed(getSimulation);

  const handleSubmit = useCallback(() => {
    pushDataLayer({
      event: AnalyticEvents.priceSave,
      projectType: leadProjects?.map(({ type }) => type).join(`, `),
    });
    offer?.id &&
      editOfferPriceOverviewAsync({
        ...parsePriceOverviewValuesForAPI(getValues(), formState.dirtyFields.totalGrossPriceRounding),
        offerId: offer.id,
      }).then(onClose);
  }, [
    editOfferPriceOverviewAsync,
    formState.dirtyFields.totalGrossPriceRounding,
    getValues,
    leadProjects,
    offer?.id,
    onClose,
    pushDataLayer,
  ]);

  return (
    <SlideInViewFormWrapper onClose={onClose} onSubmit={handleSubmit} isDisabled={isDisabled}>
      <FormProvider {...formFunctions}>
        <GridLayoutWithMargins paddingBlockStart={6}>
          <Typography variant="h1">{translate(priceOverviewPage.projectsHeader)}</Typography>
        </GridLayoutWithMargins>
        {offerPriceOverViewToShow?.bids?.map(({ id, additions, name, deductions }, index) => {
          return (
            <PriceOverviewProjectRow
              name={name ?? ``}
              key={id}
              bidIndex={index}
              translate={translate}
              translation={priceOverviewPage}
              isDisabled={isDisabled}
              debouncedSubmit={debouncedSubmit}
              additions={additions}
              deductions={deductions}
            />
          );
        })}
        <GridLayoutWithMargins>
          <section className="column gap-small">
            <Typography variant="h2">{translate(priceOverviewPage.totalHeader)}</Typography>
            <div className="column gap-small">
              <div className="row space-between">
                <Typography variant="h4">{translate(priceOverviewPage.sumExcVat)}</Typography>
                <Typography variant="h4">{getFormattedPriceWithStaticFraction(sumValues.totalNetPrice, 2)}</Typography>
              </div>
              <div className="row space-between">
                <Typography>{translate(priceOverviewPage.vat)}</Typography>
                <Typography>{getFormattedPriceWithStaticFraction(sumValues.calculatedVatPrice, 2)}</Typography>
              </div>
              <RoundingEditRow
                control={control}
                fieldName="totalGrossPriceRounding"
                offerLocked={isDisabled}
                onReset={() => {
                  setValue(`totalGrossPriceRounding`, offerPriceOverview?.defaultTotalGrossPriceRounding ?? 0);
                  setValue(`shouldResetRounding`, true);
                }}
              />
              <div className="row space-between">
                <Typography>{translate(priceOverviewPage.sumIncVat)}</Typography>
                <Typography>{getFormattedPriceWithStaticFraction(sumValues.netPriceWithVat, 2)}</Typography>
              </div>
            </div>
          </section>
          <Box mt={4}>
            <Divider />
          </Box>
        </GridLayoutWithMargins>
      </FormProvider>
      <DevTool control={control} />
    </SlideInViewFormWrapper>
  );
};
