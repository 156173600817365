import React, { useCallback } from 'react';
import { OfferAgreementProjectProductRowDto } from '@generatedTypes/data-contracts';
import ProductFallback from '@assets/svg/Article fallback.svg';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Typography from '@mui/material/Typography';
import { getFormattedNumberWithStaticFraction } from '@pages/NewLeads/utils';
import { ListItem } from '@components/ListMui/ListItem';
import { Stack } from '@mui/material';
import { LinkButton } from '@components/button/LinkButton';
import { OfferAgreementPackageDetailsProps } from '@components/PackageDetailsModal/PackageDetailsModalFromOfferAgreement';

type ProductsListProps = {
  products: OfferAgreementProjectProductRowDto[];
  setPackageDataToDetailsModal: (data: OfferAgreementPackageDetailsProps) => void;
};

export const ProductsList: React.FC<ProductsListProps> = ({ products, setPackageDataToDetailsModal }) => {
  const {
    translate,
    translations: {
      leads: { offer },
    },
  } = useTranslations();

  const imageError = useCallback(function (e: React.SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget?.classList.add(`hidden`);
    e.currentTarget?.parentElement?.querySelector(`svg`)?.classList.remove(`hidden`);
  }, []);

  return (
    <Stack spacing={1} component="ul" sx={{ padding: 0 }}>
      {products.length > 0
        ? products.map((product) => {
            const warranty = product.productWarranty;
            const subtitle = `${product.name}${product.manufacturerName ? `, ${product.manufacturerName}` : ``}`;

            return (
              <ListItem
                key={`${product.name}-${product.categoryName}`}
                title={product?.categoryName}
                avatar={
                  <img src={product.productImageUrl || ProductFallback} onError={imageError} alt="product image" />
                }
                subtitle={
                  <Stack>
                    {product.packageItems?.length ? (
                      <LinkButton
                        onClick={() => {
                          setPackageDataToDetailsModal({
                            products: product.packageItems ?? [],
                            packageName: product.name,
                            packageCategoryName: product.categoryName,
                          });
                        }}
                      >
                        {subtitle}
                      </LinkButton>
                    ) : (
                      <Typography variant="subtitle1">{subtitle}</Typography>
                    )}
                    {!!warranty && (
                      <Typography variant="subtitle1">{`${warranty} ${translate(offer.productWarrantySuffix)}`}</Typography>
                    )}
                  </Stack>
                }
                secondaryAction={getFormattedNumberWithStaticFraction(product.quantity, null, product.unit) ?? null}
              />
            );
          })
        : translate(offer.noMaterials)}
    </Stack>
  );
};
