import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { HorizontalLine } from '@components/dividers/horizontal-line';
import { OfferPriceOverviewDto } from '@generatedTypes/data-contracts';
import { getFormattedNumberWithStaticFraction, getFormattedPriceWithStaticFraction } from '@pages/NewLeads/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Button, Grid, Stack } from '@mui/material';
import { getDraftUrl } from './utils';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from '@redux/reducers/slices/user';
import { RoundingEditRow } from '@pages/NewLeads/offer/price/RoundingEditRow';
import { theme } from 'src/styles/theme/theme';

type OfferPriceCardProps = {
  offerPriceOverview: OfferPriceOverviewDto | null;
  onShowOfferPriceDetails: () => void;
  offerId: string;
  onUpdateRounding: (newRounding: number | null) => void;
  isDisabled: boolean;
  offerLocked: boolean;
  projectTypes: string;
};

export const OfferPriceCard: React.FC<OfferPriceCardProps> = ({
  onShowOfferPriceDetails,
  offerPriceOverview,
  offerId,
  onUpdateRounding,
  isDisabled,
  offerLocked,
  projectTypes,
}) => {
  const pushDataLayer = useAnalytics();
  const {
    translate,
    translations: {
      leads: {
        offer: { price, priceOverviewPage },
      },
    },
  } = useTranslations();

  const isPartnerUser = useSelector(selectIsPartnerUser);

  const { handleSubmit, control, reset } = useForm<{ totalNetPriceRounding: number | null }>({
    values: { totalNetPriceRounding: offerPriceOverview?.totalGrossPriceRounding ?? 0 },
  });

  const navigate = useNavigate();

  const onSubmit = useCallback(() => {
    handleSubmit((values) => {
      onUpdateRounding(values.totalNetPriceRounding);
    })();
  }, [handleSubmit, onUpdateRounding]);

  const onReset = useCallback(() => {
    reset({ totalNetPriceRounding: null });
    onSubmit();
  }, [reset, onSubmit]);

  const onDraftClicked = useCallback(() => {
    pushDataLayer({ event: AnalyticEvents.offerNext, projectType: projectTypes });
    navigate(getDraftUrl(offerId));
  }, [navigate, offerId, projectTypes, pushDataLayer]);

  return (
    <Stack gap={3} padding={theme.spacing(2, 3)}>
      <div className="row gap-small align-center">
        <h2 className="bold">{translate(price.priceTitle)}</h2>
      </div>
      <div className="column gap">
        {offerPriceOverview?.bids?.length ? (
          offerPriceOverview?.bids?.map((bid) => {
            return (
              <div key={bid.id} className="column gap">
                <div className="row space-between">
                  <span>{bid.name}</span>
                  <span>{getFormattedNumberWithStaticFraction(bid.totalNetPriceWithoutDeduction || 0, 2)}</span>
                </div>
                {bid.deductions.map(({ name, netDeduction, value }) => (
                  <div className="row space-between" key={`${name}-${value}`}>
                    <span>{name}</span>
                    <span>{`- ${getFormattedNumberWithStaticFraction(netDeduction || 0, 2)}`}</span>
                  </div>
                ))}
                <HorizontalLine />
              </div>
            );
          })
        ) : (
          <div className="column gap">
            <p>{translate(price.noProjectInfo)}</p>
            <HorizontalLine />
          </div>
        )}
      </div>

      <section className="column gap-small">
        <h3 className="bold">{translate(priceOverviewPage.totalHeader)}</h3>
        <div className="column gap-small">
          <div className="row space-between">
            <p className="bold">{translate(price.totalNet)}</p>
            <p className="bold">{getFormattedPriceWithStaticFraction(offerPriceOverview?.totalNetPrice ?? 0, 2)}</p>
          </div>
          <div className="row space-between">
            <p>{translate(priceOverviewPage.vat)}</p>
            <p>{getFormattedPriceWithStaticFraction(offerPriceOverview?.totalTax ?? 0, 2)}</p>
          </div>
          <RoundingEditRow
            control={control}
            formDisabled={isDisabled}
            offerLocked={offerLocked}
            onSubmit={onSubmit}
            onReset={onReset}
            initialNetPriceRounding={offerPriceOverview?.totalGrossPriceRounding ?? 0}
            fieldName="totalNetPriceRounding"
          />
          <div className="row space-between">
            <p>{translate(price.totalGross)}</p>
            <p>{getFormattedPriceWithStaticFraction(offerPriceOverview?.totalGrossPriceIncludingRounding ?? 0)}</p>
          </div>
        </div>
      </section>
      <HorizontalLine />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {isPartnerUser && (
            <Button
              variant="outlined"
              disabled={isDisabled}
              onClick={onShowOfferPriceDetails}
              fullWidth
              className="no-break"
            >
              {translate(price.goToPriceDetailsButton)}
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            disabled={isDisabled || offerPriceOverview?.bids?.length === 0}
            onClick={onDraftClicked}
            fullWidth
          >
            {translate(price.goToDraftButton)}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
