import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useMountTransition } from '@hooks/useMountTransition';
import './slideInView.css';

export interface SlideInViewWrapper {
  isVisible: boolean;
  children: ReactNode;
}

export const SlideInView: React.FC<SlideInViewWrapper> = ({ isVisible, children }) => {
  const hasTransitionedIn = useMountTransition(isVisible, 300);

  useEffect(() => {
    if (!isVisible && !hasTransitionedIn) {
      // Reactivate interactions outside slide in view when closed
      document.querySelector(`#root`)?.removeAttribute(`inert`);
    } else {
      // Trap interactions inside slide in view when open
      document.querySelector(`#root`)?.setAttribute(`inert`, `true`);
    }
    return () => {
      document.querySelector(`#root`)?.removeAttribute(`inert`);
    };
  }, [isVisible, hasTransitionedIn]);

  return !isVisible && !hasTransitionedIn
    ? null
    : createPortal(
        <div className={classNames(`fh fv slide-in-view-wrapper column`, { visible: hasTransitionedIn && isVisible })}>
          {children}
        </div>,
        document.querySelector(`body`) as HTMLElement,
      );
};
