import { ChangeEventHandler } from 'react';
import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { Numeric as NumericDumb, NumericProps as NumericPropsDumb } from '../dumb';
import { roundNumber } from '@utils/round-number';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FormHandlingErrors } from '@assets/translations/translations';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';

const cleanValue = (value: string, suffix?: string) => (suffix ? value.replaceAll(suffix, ``) : value);

const parseValue = (value: string) => {
  const isNumber = !isNaN(Number(value));
  return isNumber ? Number(value) : Number(parseInputValueToSubmitNumberValue(value));
};

const stepValue = (value: number, step: number, precision: number) => {
  const parsedValue = parseValue(value.toString());
  return roundNumber(parsedValue + step, precision);
};

const getNumberInRange = (value: number, min: number | undefined, max: number | undefined) => {
  if (min !== undefined && value < min) {
    return min;
  }
  if (max !== undefined && value > max) {
    return max;
  }
  return value;
};

interface NumericProps<T extends FieldValues>
  extends Omit<NumericPropsDumb, `onAdd` | `onSubtract` | `onChange` | `value` | `name` | `refresh`> {
  control: Control<T>;
  step?: number;
  name: Path<T>;
  precision?: number;
}

export const Numeric = <T extends FieldValues>({
  control,
  name,
  min,
  max,
  step = 1,
  precision = 3,
  isRefreshable,
  ...props
}: NumericProps<T>) => {
  const {
    field,
    fieldState: { error, isDirty },
    formState,
  } = useController({
    name,
    control,
  });

  const {
    translate,
    translations: { formHandlingErrors },
  } = useTranslations();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    const cleanedValue = cleanValue(value, props.suffix);
    const parsedValue = parseValue(cleanedValue);
    const roundedNumber = roundNumber(parsedValue, precision);
    const numberInRange = getNumberInRange(roundedNumber, min, max);
    field.onChange(numberInRange);
  };

  const handleAdd = () => {
    const parsedValue = parseValue(field.value);
    const steppedValue = stepValue(parsedValue, step, precision);
    const numberInRange = getNumberInRange(steppedValue, min, max);
    field.onChange(numberInRange);
  };

  const handleSubtract = () => {
    const parsedValue = parseValue(field.value);
    const steppedValue = stepValue(parsedValue, -step, precision);
    const numberInRange = getNumberInRange(steppedValue, min, max);
    field.onChange(numberInRange);
  };

  return (
    <NumericDumb
      error={error && translate(formHandlingErrors[error.message as keyof FormHandlingErrors])}
      name={field.name}
      onAdd={handleAdd}
      onChange={handleChange}
      onSubtract={handleSubtract}
      value={field.value}
      isRefreshable={isRefreshable && isDirty}
      refresh={() => field.onChange(formState.defaultValues?.[name])}
      {...props}
    />
  );
};
