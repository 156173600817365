export const fileDownloadActions = (blob: Blob, filename: string): boolean => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement(`a`);
  link.href = url;
  if (filename) {
    link.setAttribute(`download`, filename);
    document.body.appendChild(link);
    link.click();
    return true;
  }
  return false;
};

export const getExportListFileName = (dataType: string): string => {
  const Prefix = `MyEnergy`;
  //return filename that looks like MyEnergy Filialer 20241122.xlsx, where Filialer is the dataType and 20241122 is the current date
  return `${Prefix} ${dataType} ${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}.xlsx`;
};
