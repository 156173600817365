import { ActionInformationModal } from '@components/ActionInformationModal/ActionInformationModal';
import { Skeleton, Stack, Typography } from '@mui/material';
import { ProductsListForOffer } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/ProductsListForOffer';
import { ProductPackageItemDto } from '@generatedTypes/data-contracts';

type PackageDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  categoryName: string;
  packageName: string;
  products: ProductPackageItemDto[];
  isLoading?: boolean;
};

export const PackageDetailsModal = ({
  isOpen,
  onClose,
  packageName,
  categoryName,
  products,
  isLoading,
}: PackageDetailsModalProps) => {
  return (
    <ActionInformationModal
      header={isLoading ? <Skeleton variant="rectangular" height={40} width={150} /> : categoryName}
      isOpen={isOpen}
      onConfirm={onClose}
      confirmText="OK"
    >
      <Stack spacing={2} sx={(theme) => ({ margin: theme.spacing(2, 4) })}>
        <Typography variant="h2">{packageName}</Typography>
        {isLoading ? (
          <Skeleton variant="rectangular" height={40} width={300} />
        ) : (
          <Typography>{`${categoryName} består av följande artiklar`}</Typography>
        )}
        {isLoading ? (
          <Stack spacing={2}>
            <Skeleton variant="rectangular" height={64} />
            <Skeleton variant="rectangular" height={64} />
            <Skeleton variant="rectangular" height={64} />
          </Stack>
        ) : (
          <ProductsListForOffer ProductCategoriesName={categoryName} products={products} />
        )}
      </Stack>
    </ActionInformationModal>
  );
};
