import { FieldValues, useWatch } from 'react-hook-form';
import { ProductExistenceDto } from '@generatedTypes/data-contracts';
import { useCheckProductByEnumber } from '@services/api/projectProducts/checkProductByEnumber';
import { useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { InputWrapper, InputWrapperProps } from '@components/controls/react-hook-form-friendly/smart';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type EnumberSearchProps<T extends FieldValues> = InputWrapperProps<T> & {
  onEnumberSearch: (product: ProductExistenceDto | null) => void;
  showLastDivider?: boolean;
};

export const EnumberSearch = <T extends FieldValues>({
  control,
  name,
  label,
  isRequired,
  onEnumberSearch,
  showLastDivider = true,
}: EnumberSearchProps<T>) => {
  const {
    translate,
    translations: {
      common: {
        components: { enumberSearch },
      },
    },
  } = useTranslations();
  const { productExistence, asyncCheckProductByEnumber, isLoadingCheckProductByEnumber } = useCheckProductByEnumber();
  const eNumber = useWatch({ control, name });

  const searchProduct = useCallback(async () => {
    const result = await asyncCheckProductByEnumber(eNumber);
    onEnumberSearch(result);
  }, [asyncCheckProductByEnumber, eNumber, onEnumberSearch]);

  return (
    <Stack spacing={2}>
      <InputWrapper
        control={control}
        name={name}
        label={label ?? translate(enumberSearch.name)}
        isRequired={isRequired}
      />
      <Button variant="outlined" onClick={searchProduct} disabled={isLoadingCheckProductByEnumber}>
        {translate(enumberSearch.searchButton)}
      </Button>
      {productExistence && !productExistence.exists && (
        <Stack spacing={3}>
          <Divider />
          <Typography variant="subtitle2" textAlign="center">
            {translate(enumberSearch.noResults)}
          </Typography>
          {showLastDivider && <Divider />}
        </Stack>
      )}
      {productExistence && productExistence.exists && productExistence.productId && (
        <Stack spacing={3}>
          <Divider />
          <Typography variant="subtitle2" textAlign="center">
            {translate(enumberSearch.productExists)}
          </Typography>
          {showLastDivider && <Divider />}
        </Stack>
      )}
    </Stack>
  );
};
