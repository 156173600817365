import React, { useCallback } from 'react';
import { ProductDto, ProductPackageItemDto, ProjectProductRowDto } from '@generatedTypes/data-contracts';
import ProductFallback from '@assets/svg/Article fallback.svg';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Typography from '@mui/material/Typography';
import { getFormattedNumberWithStaticFraction } from '@pages/NewLeads/utils';
import { ListItem } from '@components/ListMui/ListItem';
import { Stack } from '@mui/material';
import { LinkButton } from '@components/button/LinkButton';
import { useDispatch } from 'react-redux';
import { setPackageDataToDetailsModal } from '@redux/reducers/slices/leadsPage';

function productIsProductDto(
  product: ProductDto | ProjectProductRowDto | ProductPackageItemDto,
): product is ProductDto {
  return Object.hasOwn(product, `name`) && Object.hasOwn(product, `id`);
}

function productIsProjectProductRowDto(
  product: ProductDto | ProjectProductRowDto | ProductPackageItemDto,
): product is ProjectProductRowDto {
  return Object.hasOwn(product, `productName`) && Object.hasOwn(product, `productId`);
}

type ProductsListProps = {
  products: ProductDto[] | ProjectProductRowDto[] | ProductPackageItemDto[];
  ProductCategoriesName?: string;
};

export const ProductsListForOffer: React.FC<ProductsListProps> = ({ products, ProductCategoriesName }) => {
  const {
    translate,
    translations: {
      leads: { offer },
    },
  } = useTranslations();

  const dispatch = useDispatch();

  const imageError = useCallback(function (e: React.SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget?.classList.add(`hidden`);
    e.currentTarget?.parentElement?.querySelector(`svg`)?.classList.remove(`hidden`);
  }, []);

  return (
    <Stack spacing={1} component="ul" sx={{ padding: 0 }}>
      {products.length > 0
        ? products.map((product) => {
            const imageUrl =
              (`productImageUrl` in product
                ? product.productImageUrl
                : `imageUrl` in product
                  ? product.imageUrl
                  : null) ?? ProductFallback;

            const warranty = (product as ProjectProductRowDto).productWarranty ?? (product as ProductDto).warranty;

            const categoryName = ProductCategoriesName || (product as ProjectProductRowDto)?.productCategoryName;

            const subtitle = productIsProductDto(product)
              ? `${product.name}${product.manufacturerName ? `, ${product.manufacturerName}` : ``}`
              : productIsProjectProductRowDto(product)
                ? `${product.productName}${product.manufacturerName ? `, ${product.manufacturerName}` : ``}`
                : product.name;
            return (
              <ListItem
                key={(product as ProductDto)?.name ?? (product as ProjectProductRowDto)?.productName}
                title={categoryName}
                avatar={imageUrl && <img src={imageUrl || ``} onError={imageError} alt="product image" />}
                subtitle={
                  <Stack>
                    {(product as ProductDto).isPackage ? (
                      <LinkButton
                        onClick={() => {
                          const idToFetch = (product as ProjectProductRowDto)?.productId ?? (product as ProductDto)?.id;
                          const nameToFetch =
                            (product as ProjectProductRowDto)?.productName ?? (product as ProductDto)?.name;
                          dispatch(setPackageDataToDetailsModal({ id: idToFetch, name: nameToFetch, categoryName }));
                        }}
                      >
                        {subtitle}
                      </LinkButton>
                    ) : (
                      <Typography variant="subtitle1">{subtitle}</Typography>
                    )}
                    {!!warranty && (
                      <Typography variant="subtitle1">{`${warranty} ${translate(offer.productWarrantySuffix)}`}</Typography>
                    )}
                  </Stack>
                }
                secondaryAction={
                  getFormattedNumberWithStaticFraction(
                    (product as ProjectProductRowDto)?.quantity,
                    null,
                    (product as ProjectProductRowDto)?.unit,
                  ) ?? null
                }
              />
            );
          })
        : translate(offer.noMaterials)}
    </Stack>
  );
};
