import { useCallback, useEffect, useRef, useState } from 'react';
import { Timeout } from 'react-number-format/types/types';

//This waiting time is needed to prevent changing the zoom while panels are being generated
//Otherwise panels won't redraw on new zoom
const INITIAL_PANELS_GENERATION_WAITING_TIME = 1000;

export const useHandleMaxZoom = ({
  forceZoomFromGoogle = false,
  map,
}: {
  map: google.maps.Map | null;
  forceZoomFromGoogle?: boolean;
}) => {
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);
  const timeoutRef = useRef<Timeout | null>(null);
  const [initialized, setInitialized] = useState(false);

  const handleMaxZoom = useCallback(() => {
    if (!map) return;
    const center = map.getCenter();
    const zoom = map.getZoom();
    if (!center || !zoom) return;
    new google.maps.MaxZoomService().getMaxZoomAtLatLng(center, (maxZoom) => {
      if (maxZoom.status === google.maps.MaxZoomStatus.OK && zoom > maxZoom.zoom) {
        map.setZoom(maxZoom.zoom);
      }
    });
  }, [map]);
  useEffect(() => {
    if (map && !listenersRef.current.length && !initialized) {
      timeoutRef.current = setTimeout(() => {
        if (forceZoomFromGoogle) {
          listenersRef.current.push(map.addListener(`dragend`, handleMaxZoom));
          listenersRef.current.push(map.addListener(`zoom_changed`, handleMaxZoom));
        }
        handleMaxZoom();
        setInitialized(true);
      }, INITIAL_PANELS_GENERATION_WAITING_TIME);
    }

    return () => {
      if (initialized) {
        if (listenersRef.current.length) {
          listenersRef.current.forEach((listener) => {
            google.maps.event.removeListener(listener);
          });
          listenersRef.current = [];
        }
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
      }
    };
  }, [map, handleMaxZoom, forceZoomFromGoogle, initialized]);
};
