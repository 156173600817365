import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { useGetManageableProducts } from '@services/api/productManagement/products';
import { Stack, Typography } from '@mui/material';
import { ProductSearchAutocomplete } from './ProductSearchAutocomplete';
import { AddEditManageableMaterialOrPackageValues } from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageForm';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type ProductPackageItemsSearch = {
  control: Control<AddEditManageableMaterialOrPackageValues>;
};

export function ProductPackageItemsSearch({ control }: ProductPackageItemsSearch) {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { addEditForm },
      },
    },
  } = useTranslations();

  const { insert } = useFieldArray<AddEditManageableMaterialOrPackageValues>({
    control,
    name: `productPackageItems`,
  });

  const listItems = useWatch({ control, name: `productPackageItems`, defaultValue: [] }) ?? [];

  const { manageableProducts } = useGetManageableProducts();

  const productsToDisplay = manageableProducts.filter((product) => {
    const isProductInList = listItems.some((currentProduct) => currentProduct.productId === product.id);
    return !isProductInList && !product.isPackage;
  });

  const onProductSelect = (productId: number) => {
    if (productId && !listItems.some((currentProduct) => currentProduct.productId === productId)) {
      insert(listItems.length, { productId, quantity: 1 });
    }
  };

  return (
    <Stack spacing={2}>
      <ProductSearchAutocomplete options={productsToDisplay} onProductSelect={onProductSelect} />
      <Typography variant="subtitle2">{translate(addEditForm.materialSearchDescription)}</Typography>
    </Stack>
  );
}
