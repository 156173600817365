import { authFetch } from '@services/api/utils';
import { ProductPackageItemDto } from '@generatedTypes/data-contracts';
import { useSelector } from 'react-redux';
import { PRODUCT_PACKAGE } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { selectPackageIdToDetailsModal } from '@redux/reducers/slices/leadsPage';

const fetchPackageItems = async (packageId: number) => {
  const url = `${process.env.PROTECTED_API_URL}/product-packages/${packageId}/items`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ProductPackageItemDto[];
  }
  throw new Error(json.message);
};

export const useGetPackageItems = () => {
  const currentPackageId = useSelector(selectPackageIdToDetailsModal);
  const { data, isLoading } = useCustomQuery({
    queryFn: () => fetchPackageItems(currentPackageId as number),
    enabled: Boolean(currentPackageId),
    queryKey: [PRODUCT_PACKAGE, currentPackageId],
  });

  return { packageItems: data, isLoadingPackageItems: isLoading };
};
