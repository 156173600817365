import { ActionInformationModal } from '@components/ActionInformationModal/ActionInformationModal';
import { Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useGetContactData } from '@services/api/contactData/contactData';
import { Card } from '@components/Card/Card';
import { parsePhoneNumber } from '@utils/parsers';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type HelpSupportModalContentProps = {
  isOpen: boolean;
  onClose: () => void;
};

const HelpSupportModalContent = ({ onClose, isOpen }: HelpSupportModalContentProps) => {
  const {
    translate,
    translations: { contactModal },
  } = useTranslations();

  const { isContactDataLoading, contactData } = useGetContactData();
  const theme = useTheme();

  if (isContactDataLoading) {
    return null;
  }

  return (
    <ActionInformationModal
      header={translate(contactModal.modalHeader)}
      isOpen={isOpen}
      confirmText={translate(contactModal.submitButton)}
      onConfirm={onClose}
    >
      <Card sx={{ border: `none` }}>
        <Stack spacing={2}>
          <Typography variant="h2">{translate(contactModal.header)}</Typography>
          {contactData?.dealer && (
            <Stack spacing={1}>
              <Typography variant="body2">
                {translate(contactModal.dealerHeader)} {contactData.dealer.name}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Link color={theme.palette.rexelEnergy.ctaBlue} href={`mailto:${contactData.dealer.email}`}>
                  {translate(contactModal.email)}
                </Link>
                {contactData.dealer.phone && (
                  <Typography variant="body1">{`${translate(contactModal.telephone)} ${parsePhoneNumber(contactData.dealer.phone)}`}</Typography>
                )}
              </Stack>
            </Stack>
          )}
          {!!contactData?.dealer?.contactPeople?.length && (
            <Stack spacing={1}>
              <Typography variant="body2">{translate(contactModal.salesHeader)}</Typography>
              {contactData.dealer.contactPeople.map((contactPerson) => (
                <Stack key={contactPerson.email} direction="row" spacing={1} alignItems="center">
                  <Link
                    color={theme.palette.rexelEnergy.ctaBlue}
                    href={`mailto:${contactPerson.email}`}
                  >{`${contactPerson.firstName} ${contactPerson.lastName}`}</Link>
                  {contactPerson.phone && (
                    <Typography variant="body1">{`${translate(contactModal.telephone)} ${parsePhoneNumber(contactPerson.phone)}`}</Typography>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
          <Stack spacing={1} mb={5}>
            <Typography variant="body2">{translate(contactModal.customerSupportHeader)}</Typography>
            <Link color={theme.palette.rexelEnergy.ctaBlue} href={contactData?.supportWeb ?? ``} target="_blank">
              {translate(contactModal.contactRexel)}
            </Link>
          </Stack>
        </Stack>
      </Card>
    </ActionInformationModal>
  );
};

export const HelpSupportModal = () => {
  const {
    translate,
    translations: { contactModal },
  } = useTranslations();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        sx={{ justifyContent: `flex-start` }}
        variant="text"
        className="nav-item profile-item"
        onClick={openModal}
      >
        {translate(contactModal.openContactButton)}
      </Button>
      {isOpen && <HelpSupportModalContent isOpen={isOpen} onClose={closeModal} />}
    </>
  );
};
