import { ProjectAdditionTemplateDto } from '@generatedTypes/data-contracts';
import { CheckBoxProps as CheckBoxDumbProps } from '@components/controls/react-hook-form-friendly/dumb/Checkbox/Checkbox';
import { Stack, Typography } from '@mui/material';

type TemplateCheckboxRowProps = {
  currencyTranslation: string;
  pieceTranslation: string;
  hourTranslation: string;
  netCostPerHour: number;
};

export const getMapAdditionTemplateToCheckboxRow =
  ({ currencyTranslation, pieceTranslation, hourTranslation, netCostPerHour }: TemplateCheckboxRowProps) =>
  (
    additionsTemplate: ProjectAdditionTemplateDto,
  ): Omit<CheckBoxDumbProps, `name` | `onChange` | `checked` | `isLabelOnRight`> => ({
    label: (
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Stack justifyContent="center">
          <Typography>{additionsTemplate.name}</Typography>

          <Typography noWrap variant="subtitle1">
            {additionsTemplate.quantity ? `${additionsTemplate.quantity} ${pieceTranslation}, ` : ``}
            {additionsTemplate.numberOfWorkHours ? `${additionsTemplate.numberOfWorkHours} ${hourTranslation}` : ``}
          </Typography>
        </Stack>
        <Typography noWrap>
          {additionsTemplate.netPrice * additionsTemplate.quantity +
            netCostPerHour * additionsTemplate.numberOfWorkHours}
          {` `}
          {currencyTranslation}
        </Typography>
      </Stack>
    ),
    value: additionsTemplate.id,
  });
