import { Stack, Typography } from '@mui/material';
import ProductFallback from '@assets/svg/Article fallback.svg';
import { ListItemBordered } from '@components/ListMui/ListItemBordered';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type CustomProductListItemProps = {
  category?: string;
  name?: string;
  warranty?: number;
  imageUrl?: string | null;
  selected?: boolean;
  interactive?: boolean;
};

export const CustomProductListItem: React.FC<CustomProductListItemProps> = ({
  category,
  name,
  warranty,
  imageUrl,
  selected = true,
  interactive = true,
}) => {
  const {
    translate,
    translations: { editCustomProduct },
  } = useTranslations();

  return (
    <ListItemBordered
      title={category || translate(editCustomProduct.defaultCategory)}
      backgroundColor="white"
      selected={selected}
      interactive={interactive}
      subtitle={
        <Stack>
          <Typography variant="subtitle1">{name || translate(editCustomProduct.defaultName)}</Typography>
          <Typography variant="subtitle1">
            {`${warranty || `x`} ${translate(editCustomProduct.warrantySuffix)}`}
          </Typography>
        </Stack>
      }
      avatar={<img src={imageUrl ?? ProductFallback} alt="product image" />}
    />
  );
};
