import { AddEditCustomProductForm } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/addEditCustomProduct/AddEditCustomProductForm';
import {
  PutProjectProductProps,
  useUpdateProjectProductMutation,
} from '@services/api/projectProducts/projectProductEdit';
import { useCreateProjectProductMutation } from '@services/api/projectProducts/ProjectProductAdd';
import { useCallback } from 'react';
import { CreateProjectProductRequest } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { setCurrentProductsAttributesFilters } from '@redux/actions/lead';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentProjectId } from '@redux/selectors/lead';

type AddEditCustomProductFormWrapperProps = {
  onClose: () => void;
  disableForm?: boolean;
};

export const AddEditCustomProductFormWrapper: React.FC<AddEditCustomProductFormWrapperProps> = ({
  onClose,
  disableForm,
}) => {
  const pushDataLayer = useAnalytics();
  const projectProductId = useSelector(selectCurrentProjectId);
  const dispatch = useDispatch();
  const { leadProjects, refetch: refetchLeadsProjects } = useGetLeadProjects();
  const { updateProjectProduct, isUpdatingProjectProduct, updateProjectProductBeValidationErrors } =
    useUpdateProjectProductMutation();

  const { addProjectProduct, isAddingProjectProduct, addProjectProductBeValidationErrors } =
    useCreateProjectProductMutation();

  const onSubmitProjectProduct = useCallback(
    (projectProduct: PutProjectProductProps | CreateProjectProductRequest) => {
      if (Object.hasOwn(projectProduct, `id`)) {
        pushDataLayer({
          event: AnalyticEvents.materialChange,
          projectType: leadProjects?.find((project) => project.id === projectProductId)?.type,
        });
        updateProjectProduct(projectProduct as PutProjectProductProps);
        onClose();
      }
      if (Object.hasOwn(projectProduct, `projectId`)) {
        pushDataLayer({
          event: AnalyticEvents.materialAdd,
          projectType: leadProjects?.find((project) => project.id === projectProductId)?.type,
        });
        addProjectProduct(projectProduct as CreateProjectProductRequest);
        onClose();
      }
      dispatch(setCurrentProductsAttributesFilters([]));
    },
    [dispatch, pushDataLayer, leadProjects, updateProjectProduct, onClose, projectProductId, addProjectProduct],
  );

  return (
    <AddEditCustomProductForm
      onClose={onClose}
      onSubmitProjectProduct={onSubmitProjectProduct}
      refetchLeadProjects={refetchLeadsProjects}
      disableForm={isUpdatingProjectProduct || isAddingProjectProduct || disableForm}
      beValidationResults={updateProjectProductBeValidationErrors || addProjectProductBeValidationErrors}
    />
  );
};
