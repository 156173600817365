import Autocomplete from '@mui/material/Autocomplete';
import { Controller, FieldValues } from 'react-hook-form';
import { InputWrapperProps } from '@components/controls/react-hook-form-friendly/smart';
import TextField from '@mui/material/TextField';

export type AutocompleteProps<T extends FieldValues> = InputWrapperProps<T> & {
  options: string[];
  freeSolo?: boolean;
};

export function AutocompleteWrapper<T extends FieldValues>({
  options,
  freeSolo = false,
  control,
  name,
  label,
  isRequired,
}: AutocompleteProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <Autocomplete
          onBlur={onBlur}
          onChange={(_, v) => onChange(v)}
          value={value}
          inputValue={value}
          disableClearable
          selectOnFocus
          clearOnBlur
          onInputChange={(_, v) => onChange(v)}
          freeSolo={freeSolo}
          autoSelect
          id={name}
          options={options || []}
          renderInput={(params) => (
            <TextField
              {...params}
              required={isRequired}
              label={label}
              ref={ref}
              error={!!error}
              helperText={error?.message}
              InputProps={{ ...params.InputProps, endAdornment: null }}
            />
          )}
        />
      )}
    />
  );
}
