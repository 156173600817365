import {
  AddEditManageableMaterialOrPackageForm,
  AddEditManageableMaterialOrPackageValues,
} from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/AddEditManageableMaterialOrPackageForm';
import {
  ConflictResponseRow,
  useCreateManageableProduct,
  useCreateManageableProductPackage,
  useDeleteManageableProduct,
  useDeleteManageableProductPackage,
  useGetSelectedManageableProduct,
  useGetSelectedManageableProductPackage,
  useUpdateManageableProduct,
  useUpdateManageableProductPackage,
} from '@services/api/productManagement/products';
import { useSelector } from 'react-redux';
import { selectSelectedManageableProductCategoryId } from '@redux/reducers/slices/productManagement';
import {
  getProductFieldsFromFormValues,
  getProductPackageFieldsFromFormValues,
  useMapAttributeValues,
} from '@pages/systemSettings/materialManagement/AddEditManageableMaterialOrPackageForm/utils';
import { useCallback, useMemo, useState } from 'react';
import { useToggle } from '@hooks/useToggle';
import { ActionInformationModal } from '@components/ActionInformationModal/ActionInformationModal';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Card } from '@components/Card/Card';
import { useSnackbar } from 'notistack';

type AddEditManageableMaterialOrPackageFormProps = {
  onClose: () => void;
};

export const AddEditManageableMaterialOrPackageFormWrapper: React.FC<AddEditManageableMaterialOrPackageFormProps> = ({
  onClose,
}) => {
  const {
    translate,
    translations: {
      systemSettings: {
        materialManagementPage: { deleteModal },
      },
    },
  } = useTranslations();

  const { enqueueSnackbar } = useSnackbar();
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();
  const [conflictErrors, setConflictErrors] = useState<ConflictResponseRow[]>([]);
  const onConflict = useCallback(
    (errors: ConflictResponseRow[]) => {
      toggleDeleteModal();
      setConflictErrors(errors);
    },
    [toggleDeleteModal],
  );
  const onDeletion = useCallback(() => {
    onClose();
    enqueueSnackbar(translate(deleteModal.deleteConfirmation), {
      preventDuplicate: true,
      variant: `success`,
      anchorOrigin: { vertical: `bottom`, horizontal: `center` },
    });
  }, [deleteModal.deleteConfirmation, enqueueSnackbar, onClose, translate]);

  const { createManageableProduct, isCreatingManageableProduct, createManageableProductValidationErrors } =
    useCreateManageableProduct({ onSuccess: onClose });
  const { updateManageableProduct, isUpdatingManageableProduct } = useUpdateManageableProduct({ onSuccess: onClose });
  const { deleteManageableProduct } = useDeleteManageableProduct({ onSuccess: onDeletion, onConflict });
  const {
    createManageableProductPackage,
    isCreatingManageableProductPackage,
    createManageableProductPackageValidationErrors,
  } = useCreateManageableProductPackage({ onSuccess: onClose });
  const { updateManageableProductPackage, isUpdatingManageableProductPackage } = useUpdateManageableProductPackage({
    onSuccess: onClose,
  });
  const { deleteManageableProductPackage } = useDeleteManageableProductPackage({ onSuccess: onDeletion });

  const selectedCategoryId = useSelector(selectSelectedManageableProductCategoryId);
  const { selectedManageableProduct, isLoadingSelectedManageableProduct } = useGetSelectedManageableProduct();
  const { selectedManageableProductPackage, isLoadingSelectedManageableProductPackage } =
    useGetSelectedManageableProductPackage();
  const mapAttributeValues = useMapAttributeValues();

  const onSubmit = useCallback(
    (values: AddEditManageableMaterialOrPackageValues) => {
      if (!selectedCategoryId) {
        return;
      }
      if (values.type === `material`) {
        const props = getProductFieldsFromFormValues(values, mapAttributeValues);
        if (!props) {
          return;
        }
        if (selectedManageableProduct) {
          updateManageableProduct(props);
        } else {
          createManageableProduct({ ...props, categoryId: selectedCategoryId });
        }
      } else {
        const props = getProductPackageFieldsFromFormValues(values, mapAttributeValues);
        if (!props) {
          return;
        }
        if (selectedManageableProductPackage) {
          const { productPackageItems, ...rest } = props;
          updateManageableProductPackage({ ...rest, packageItems: productPackageItems });
        } else {
          createManageableProductPackage({ ...props, categoryId: selectedCategoryId });
        }
      }
    },
    [
      createManageableProduct,
      createManageableProductPackage,
      mapAttributeValues,
      selectedCategoryId,
      selectedManageableProduct,
      selectedManageableProductPackage,
      updateManageableProduct,
      updateManageableProductPackage,
    ],
  );

  const onDelete = useMemo(() => {
    if (selectedManageableProduct) {
      return deleteManageableProduct;
    } else if (selectedManageableProductPackage) {
      return deleteManageableProductPackage;
    }
    return undefined;
  }, [
    deleteManageableProduct,
    deleteManageableProductPackage,
    selectedManageableProduct,
    selectedManageableProductPackage,
  ]);

  const isFormLoading =
    isCreatingManageableProduct ||
    isUpdatingManageableProduct ||
    isCreatingManageableProductPackage ||
    isUpdatingManageableProductPackage;

  if (isLoadingSelectedManageableProduct || isLoadingSelectedManageableProductPackage) {
    return null;
  }

  return (
    <>
      <AddEditManageableMaterialOrPackageForm
        onClose={onClose}
        onSubmit={onSubmit}
        disabled={isFormLoading}
        errors={createManageableProductValidationErrors || createManageableProductPackageValidationErrors}
        initialMaterial={selectedManageableProduct}
        initialPackage={selectedManageableProductPackage}
        onDelete={onDelete}
      />
      <ActionInformationModal
        header={translate(deleteModal.title)}
        isOpen={isDeleteModalOpen}
        onConfirm={toggleDeleteModal}
        confirmText={translate(deleteModal.submit)}
      >
        <Card sx={{ border: `none` }}>
          <Stack>
            <Stack spacing={2}>
              <Typography variant="h2">{translate(deleteModal.header)}</Typography>
              <Typography>{translate(deleteModal.description)}</Typography>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              {conflictErrors.map((error) => (
                <Stack key={error.packageId}>
                  <Typography>{error.category}</Typography>
                  <Typography variant="subtitle2">{error.packageName}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Card>
      </ActionInformationModal>
    </>
  );
};
