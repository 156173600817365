import { ProductAttributeRowDto, ProductCategoryRowDto, ProjectProductDto } from '@generatedTypes/data-contracts';

import { AppliedFilters, ProductAttributeRowListProps, ProductAttributeRowNumberProps } from '../types';
import { getFormFilterForNumberTypeFilter } from '../numberType/utils';
import { getFormFilterForListTypeFilter } from '../listType/utils';

const getAttributeDataForProductFilterValue = (attributes: ProductAttributeRowDto[], filterValue: number) => {
  return attributes.find(({ values }) => values?.some(({ id }) => id === filterValue));
};

export const getAppliedFiltersFromProduct = (
  product: ProjectProductDto | null,
  productCategory?: ProductCategoryRowDto,
): AppliedFilters => {
  if (!product || !productCategory) {
    return [];
  }

  return product.filters.reduce<AppliedFilters>((filters, attribute) => {
    const categoryDataForProductFilterValue = getAttributeDataForProductFilterValue(
      productCategory.productsAttributes,
      attribute.id,
    );
    if (!categoryDataForProductFilterValue) {
      return filters;
    }
    const baseFilter = {
      attributeId: categoryDataForProductFilterValue?.id ?? -1,
      value: attribute.id,
      valueName: attribute.name ?? String(attribute.id),
      attributeName: categoryDataForProductFilterValue?.name ?? ``,
      active: true,
      type: categoryDataForProductFilterValue?.filterType,
    };
    if (categoryDataForProductFilterValue?.filterType === `List`) {
      const listFilter = getFormFilterForListTypeFilter({
        appliedFilter: baseFilter as ProductAttributeRowListProps,
        attribute: categoryDataForProductFilterValue,
        valuesAvailableForAttribute: new Set([attribute.id]),
      });
      filters.push({
        ...listFilter,
      });
    }
    if (categoryDataForProductFilterValue?.filterType === `Number`) {
      const numberFilter = getFormFilterForNumberTypeFilter({
        appliedFilter: baseFilter as ProductAttributeRowNumberProps,
        attribute: categoryDataForProductFilterValue,
        valuesAvailableForAttribute: new Set([attribute.id]),
      });
      filters.push({ ...numberFilter });
    }
    return filters;
  }, [] as AppliedFilters);
};
