import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { useCallback } from 'react';
import { useGetPartner } from '@services/api/partners';
import { useUpdateDeductionTemplate } from '@services/api/deductionTemplates/useUpdateDeductionTemplate';
import { useCreateDeductionTemplate } from '@services/api/deductionTemplates/useCreateDeductionTemplate';
import {
  DeductionValuesType,
  AddEditDeductionForm,
} from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProjectDeductions/Forms/EditForm/AddEditDeductionForm';
import { useGetDeductionTemplate } from '@services/api/deductionTemplates/useGetDeductionTemplate';
import { useDeleteDeductionTemplate } from '@services/api/deductionTemplates/useDeleteDeductionTemplate';

export interface EditDeductionTemplateWrapperProps {
  onClose: () => void;
}

export const EditDeductionTemplateWrapper = ({ onClose }: EditDeductionTemplateWrapperProps) => {
  const pushDataLayer = useAnalytics();
  const { partner } = useGetPartner();
  const { deleteDeductionTemplateAsync } = useDeleteDeductionTemplate({
    onSuccess: onClose,
  });
  const { updateDeductionTemplateAsync, isUpdatingDeductionTemplate, updateDeductionTemplateValidationErrors } =
    useUpdateDeductionTemplate();
  const { addDeductionTemplateAsync, isAddingDeductionTemplate, addDeductionTemplateValidationErrors } =
    useCreateDeductionTemplate();
  const { deductionTemplate } = useGetDeductionTemplate();

  const onSubmitDeductionTemplate = useCallback(
    ({ value, type, name, scope }: DeductionValuesType) => {
      pushDataLayer({
        event: AnalyticEvents.deductionTemplateAdd,
      });
      const baseData = {
        value,
        type,
        name,
        scope,
      };

      if (deductionTemplate?.id) {
        updateDeductionTemplateAsync({
          ...baseData,
          id: deductionTemplate.id,
        }).then(onClose);
      } else {
        addDeductionTemplateAsync({
          ...baseData,
          partnerId: partner?.id ?? -1,
        }).then(onClose);
      }
    },
    [
      addDeductionTemplateAsync,
      deductionTemplate?.id,
      onClose,
      partner?.id,
      pushDataLayer,
      updateDeductionTemplateAsync,
    ],
  );

  return (
    <AddEditDeductionForm
      onDelete={deleteDeductionTemplateAsync}
      closeModal={onClose}
      onSubmitDeductionTemplate={onSubmitDeductionTemplate}
      disableForm={isUpdatingDeductionTemplate || isAddingDeductionTemplate}
      beValidationResults={updateDeductionTemplateValidationErrors || addDeductionTemplateValidationErrors}
      initialDeductionTemplate={deductionTemplate}
    />
  );
};
